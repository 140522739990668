import { useContext, useEffect, useState } from "react";
import { AuthCountext } from "../AuthCountext";

function FetchInventory() {
  const { ACCOUNNTING_URL, userToken, branchId } = useContext(AuthCountext);
  const [categories, setCategories] = useState([]);
  const [items, setItems] = useState([]);
  const [units, setUnits] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [warehouses, setWarehouses] = useState([]);

  const fetchCategory = async () => {
    try {
      const response = await fetch(
        `${ACCOUNNTING_URL}/categories/${branchId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();

      if (json.length > 0) {
        setCategories(json);
      } else {
        setCategories([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchItems = async () => {
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/items/${branchId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();

      if (json.length > 0) {
        setItems(json);
      } else {
        setItems([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchUnits = async () => {
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/units/${branchId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();

      if (json.length > 0) {
        setUnits(json);
      } else {
        setUnits([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSuppliers = async () => {
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/suppliers/${branchId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();

      if (json.length > 0) {
        setSuppliers(json);
      } else {
        setSuppliers([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchBuyers = async () => {
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/buyers/${branchId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();

      if (json.length > 0) {
        setBuyers(json);
      } else {
        setBuyers([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchWareHouses = async () => {
    try {
      const response = await fetch(
        `${ACCOUNNTING_URL}/warehouses/${branchId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      if (json.length > 0) {
        setWarehouses(json);
      } else {
        setWarehouses([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchItems();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchUnits();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchSuppliers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchBuyers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchWareHouses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { categories, items, units, suppliers, buyers, warehouses };
}

export default FetchInventory;
