import React, { useContext, useState } from "react";
import { AuthCountext } from "../component/AuthCountext";
import Loader from "../component/Loader";
import AlertComp from "../component/subcomponent/AlertComp";
import ThemeSwitch from "./ThemeSwitch";
import CreatUser from "./CreatUser";
import { Input } from "antd";
import { backgroundImage } from "../assets/icons";
import WindowSize from "../component/subcomponent/WindowSize";

function SignInScreen() {
  const { login, isLoading, valid, theme, setTitle, usersData } =
    useContext(AuthCountext);
  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [formData, setFormData] = useState({
    uname: null,
    upass: null,
  });
  const windowSize = WindowSize();

  const validationFun = () => {
    if (formData.uname !== null && formData.upass !== null) {
      login(formData);
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
    }
  };

  const Valid = () => {
    if (valid !== null) {
      setIsAlert(true);
      setAlertName("alert-warning");
      alertShow();
    }
    return <AlertComp msg={valid} alert={alertName} />;
  };

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  const handleClick = (event) => {
    event.preventDefault();
    console.log(formData);
    validationFun();
  };

  return usersData ? (
    <div
      className={`flex position-relative full-width p-t-80 p-50 over-flow-scroll background-${theme} color-${theme}`}
      style={{ padding: "0px" }}
    >
      <div
        style={{
          position: "absolute",
          left: "20px",
          top: "20px",
          zIndex: "100",
        }}
      >
        <ThemeSwitch />
      </div>
      {valid !== null && <Valid />}
      {setTitle("نظام الرواتب- تسجيل الدخول")}
      {isAlert && valid === null && <AlertComp msg={alertMsg} alert={alertName} />}
      <div className="flex align-center full-width media-flex-culomn">
        <div
        className="flex flex-column align-cneter justify-center"
          style={{
            width: windowSize[0] > 900 ? "30%" : "100%",
            background: theme === "dark" ? "#04AA6D" : "#1677ff",
            height: windowSize[0] > 900 ? "100%" : "50%",
            minWidth: "500px",
            textAlign: "center",
            
          }}
        >
          <div
            className="flex align-center justify-end"
            
          >
            <h3
              className="full-width"
              style={{ fontSize: "2rem", fontWeight: "500", color: "#FAF9F6", padding: '20px' }}
            >
              متعة العمل وسهولة الوصول للبيانات الوظيفية
            </h3>
          </div>
          <img
            src={backgroundImage}
            width="100%"
            height="60%"
            alt="hero"
            style={{ objectFit: "contain", marginLeft: "auto" }}
          />
        </div>
        <div className="flex flex-row gap-10 align-center justify-center full-width">
          <form
            className={`flex flex-row justify-center align-center gap-20 p-20 full-width border-radius`}
            onSubmit={handleClick}
            style={{ maxWidth: "600px", fontSize: "small" }}
          >
            <div
              className="full-width flex flex-column justify-space-between align-center full-width"
              style={{ maxWidth: "500px" }}
            >
              <div
                className="full-width"
                style={{ position: "relative", padding: "20px 0px" }}
              >
                <h3
                  className="full-width "
                  style={{ fontSize: "2rem", fontWeight: "500" }}
                >
                  مرحبا بك في نظام الرواتب
                </h3>
                <div style={{ position: "absolute", bottom: "10px" }}>
                  <span
                    className={`text-pramary-${theme}`}
                    style={{ fontSize: "large" }}
                  >
                    الرجاء تسجيل الدخول
                  </span>
                </div>
              </div>
              <div
                className="full-width flex flex-column justify-center align-start gap-10 full-width"
                style={{ maxWidth: "500px" }}
              >
                <Input
                  type="text"
                  name="uname"
                  className={`full-width height-50 border-radius place-holder p-r-10 background-div-${theme} color-${theme}`}
                  placeholder="اسم المستخدم"
                  onChange={(e) =>
                    setFormData((prevFormData) => {
                      return {
                        ...prevFormData,
                        uname: e.target.value,
                      };
                    })
                  }
                  style={{ border: "none" }}
                />
                <Input
                  type="password"
                  name="upass"
                  className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                  placeholder="كلمة السر"
                  onChange={(e) =>
                    setFormData((prevFormData) => {
                      return {
                        ...prevFormData,
                        upass: e.target.value,
                      };
                    })
                  }
                  style={{ border: "none" }}
                />

                <button
                  className={`next-button-${theme} shadow-${theme}`}
                  style={{
                    width: "100px",
                    height: "50px",
                    margin: "0",
                    marginTop: "15px"
                  }}
                >
                  تسجيل الدخول
                </button>
                <div style={{ height: "100px" }}>
                  {isLoading && <Loader size="medium" />}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  ) : (
    <CreatUser usersData={usersData} />
  );
}

export default SignInScreen;
