import { Select } from "antd";
import React, {
  memo,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { AuthCountext } from "../AuthCountext";

const FetchItems = ({ data, setData, isloading, item, index }) => {
  const { ACCOUNNTING_URL, userToken, branchId } = useContext(AuthCountext);
  const [items, setItems] = useState([]);

  // Cache results to avoid repeated API calls
  const cache = {};

  const fetchItems = async () => {
    if (cache[branchId]) {
      setItems(cache[branchId]);
      return;
    }
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/items/${branchId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      cache[branchId] = json.length > 0 ? json : [];
      setItems(cache[branchId]);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  useEffect(() => {
    if (!items?.length) {
      fetchItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchId]);

  const handleChange = useCallback(
    (e) => {
      setData((draft) => {
        draft.invoicedetails[index] = {
          ...draft.invoicedetails[index],
          ItemID: e,
          UnitID: null,
          ConversionRate: 1,
          PricePerUnit: 1,
          Quantity: 1,
          TotalPrice: 1,
          Vat: 1,
        };
      });
    },
    [setData, index]
  );

  return (
    <Select
      autoFocus
      value={item.ItemID}
      name="ItemID"
      showSearch
      style={{ minWidth: "300px" }}
      className="height-40 border-radius"
      onChange={handleChange}
      optionFilterProp="label"
      placeholder={isloading ? "جارٍ التحميل" : "الصنف"}
      options={items.map((item) => ({
        disabled: item.is_parent === "1" || item.is_parent === 1,
        value: item.ItemID,
        label: `${item.ItemID} ${item.Name}`,
      }))}
    />
  );
};

export default memo(FetchItems);
