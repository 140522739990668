import React, { useContext, useEffect, useState } from "react";
import { Button, DatePicker, Select, Space } from "antd";
import { AuthCountext } from "../AuthCountext";
import AlertComp from "../subcomponent/AlertComp";

function StepFour({
  currentStep,
  stepLenght,
  setFormData,
  formData,
  setDisable,
}) {
  const { SERVER_URL, userToken } = useContext(AuthCountext);
  const [addButtonLoading, setaddButtonLoading] = useState(false);
  const [saveButtonDisable, setSaveButtonDisable] = useState(false);
  const [data, setData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [ispending, setIspending] = useState(false);

  // fetch for position Id from table positiontitle
  const fetchData = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/positiontitle/`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      setData(json);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchBranch = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/branch/`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      setBranchData(json);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchBranch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationFun = () => {
    if (
      formData.employeeId !== null &&
      formData.positionId !== null &&
      formData.startInPosition !== null &&
      formData.start !== null
    ) {
      // sendData();
      setIspending(true);
      setSaveButtonDisable(true);
      setaddButtonLoading(true);
      addButtonLoadingTime();
      setIsAlert(true);
      setAlertMsg(`تم تجاوز الخطوة رقم ${currentStep + 1} بنجاح`);
      setAlertName("alert-success");
      alertShow();
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
    }
  };

  const addButtonLoadingTime = () => {
    const timer = setTimeout(
      () => [
        setaddButtonLoading(false),
        setIspending(false),
        setDisable(false),
      ],
      3000
    );

    return () => {
      clearTimeout(timer);
    };
  };

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  return (
    <>
      {isAlert && <AlertComp msg={alertMsg} alert={alertName} />}
      <div
        className="flex flex-row justify-space-between gap-20 full-width media-flex-column"
        style={{ marginBottom: "20px" }}
      >
        <div className="full-width flex flex-column align-center gap-20">
          <span
            className="font-large font-bold"
            style={{ alignSelf: "center" }}
          >
            بيانات العقد وطبيعة الوظيفة
          </span>
          <Space
            direction="vertical"
            style={{ width: "70%" }}
            className="media-full-width"
          >
            <Select
              name="branchId"
              showSearch
              className={`full-width height-50 border-radius`}
              onChange={(e) =>
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    branchId: e,
                  };
                })
              }
              optionFilterProp="label"
              placeholder="الفرع"
              options={branchData.map((item) => ({
                value: item.id,
                label: item.title,
              }))}
            />

            <Select
              name="positionId"
              showSearch
              className={`full-width height-50 border-radius`}
              onChange={(e) =>
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    positionId: e,
                  };
                })
              }
              optionFilterProp="label"
              placeholder="الوظيفة"
              options={data.map((item) => ({
                value: item.id,
                label: item.title,
              }))}
            />
            <DatePicker
              style={{ width: "100%", height: "50px", borderRadius: "10px" }}
              size={"large"}
              placeholder="تاريخ بداية العقد"
              onChange={(date, dateString) =>
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    start: dateString,
                  };
                })
              }
            />

            <DatePicker
              style={{ width: "100%", height: "50px", borderRadius: "10px" }}
              size={"large"}
              placeholder="تاريخ انتهاء العقد"
              onChange={(date, dateString) =>
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    end: dateString,
                  };
                })
              }
            />

            <DatePicker
              style={{ width: "100%", height: "50px", borderRadius: "10px" }}
              size={"large"}
              placeholder="تاريخ بداية الوظيفة"
              onChange={(date, dateString) =>
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    startInPosition: dateString,
                  };
                })
              }
            />

            <DatePicker
              style={{ width: "100%", height: "50px", borderRadius: "10px" }}
              size={"large"}
              placeholder="تاريخ انتهاء الوظيفة"
              onChange={(date, dateString) =>
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    endInPosition: dateString,
                  };
                })
              }
            />

            <Button
              style={{ marginBlock: "10px", width: "100px" }}
              type="primary"
              disabled={saveButtonDisable}
              onClick={() => validationFun()}
              className={`flex flex-row ${
                addButtonLoading ? `justify-space-between` : `justify-center`
              } align-center`}
              loading={addButtonLoading}
            >
              {ispending
                ? "جارٍ الحفظ"
                : saveButtonDisable
                ? "تم الحفظ"
                : "اضافة"}
            </Button>
          </Space>
        </div>
      </div>
    </>
  );
}

export default StepFour;
