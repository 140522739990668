import { Alert, InputNumber } from "antd";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AuthCountext } from "../AuthCountext";

function GetItemStockQuantity({
  ItemID,
  WarehouseID,
  item,
  setFormData,
  index,
}) {
  const { ACCOUNNTING_URL, userToken } = useContext(AuthCountext);
  const [data, setData] = useState(0);
  const [alert, setAlert] = useState(false);
  const fetchItemsUnits = async () => {
    if (ItemID) {
      try {
        const response = await fetch(
          `${ACCOUNNTING_URL}/getItemStockQuantity/${ItemID}/${WarehouseID}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-api-key": userToken,
            },
          }
        );
        const json = await response.json();
        if (json.length > 0) {
          setAlert(false);
          setData(json[0].Quantity ? json[0].Quantity : 1);
          setFormData((draft) => {
            draft.invoicedetails[index].Quantity = 1;
            draft.invoicedetails[index].Vat = roundFunction(
              1 *
                ((draft.invoicedetails[index].PricePerUnit /
                  draft.invoicedetails[index].ConversionRate) *
                  0.15)
            );
            draft.invoicedetails[index].TotalPrice = roundFunction(
              1 *
                (draft.invoicedetails[index].PricePerUnit /
                  draft.invoicedetails[index].ConversionRate) +
                draft.invoicedetails[index].Vat
            );
          });
        } else {
          setData([]);
          setAlert(true);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchItemsUnits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ItemID, item.ConversionRate]);

  const roundFunction = useCallback((x) => {
    return Math.round((x + Number.EPSILON) * 100) / 100;
  }, []);
  return (
    <>
      <InputNumber
        min={0}
        size="large"
        max={item?.type === "purchase" ? "" : data * item.ConversionRate}
        addonAfter={
          <span style={{ fontSize: "small" }}>
            {data * item.ConversionRate}
          </span>
        }
        value={item.Quantity}
        defaultValue={1}
        name="Quantity"
        className={`height-40 full-width`}
        onChange={(e) => {
          setFormData((draft) => {
            draft.invoicedetails[index].Quantity = e;
            draft.invoicedetails[index].Vat = roundFunction(
              e * (draft.invoicedetails[index].PricePerUnit * 0.15)
            );
            draft.invoicedetails[index].TotalPrice = roundFunction(
              e * draft.invoicedetails[index].PricePerUnit +
                draft.invoicedetails[index].Vat
            );
          });
        }}
      />
      {alert && item?.type !== "purchase" && (
        <Alert
          showIcon
          style={{
            fontSize: "11px",
            margin: "2px",
            padding: "4px",
            width: "fit-content",
          }}
          message="لا يوحد مخزون كافي"
          type="error"
        />
      )}
    </>
  );
}

export default memo(GetItemStockQuantity);
