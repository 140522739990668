import { Button } from "antd";
import ParentComp from "../ParentComp";
import ItemUnitsComp from "./ItemUnitsComp";
import { useState } from "react";

function ItemUnits() {
  const [openModal, setOpenModal] = useState(false);
  const ParentButton = () => {
    return (
      <Button type="primary" onClick={() => setOpenModal(true)}>
        إضافة وحدة صنف
      </Button>
    );
  };

  
  return (
    <ParentComp
      title={"اعداد وحدات الأصناف"}
      prevPage={"نظام المخازن"}
      linkto={"/inventory"}
      button={<ParentButton />}
    >
      <ItemUnitsComp openModal={openModal} setOpenModal={setOpenModal} />
    </ParentComp>
  );
}

export default ItemUnits;
