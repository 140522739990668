import ParentComp from "../ParentComp";
import BalanceSheetComp from "./BalanceSheetComp";

function BalanceSheet() {
  return (
    <ParentComp
      title={"التقارير المالية"}
      prevPage={"النظام المحاسبي"}
      linkto={"/accounting"}
    >
      <BalanceSheetComp />
    </ParentComp>
  );
}

export default BalanceSheet;
