import ParentComp from "../ParentComp";
import StockTakingComp from "./StockTakingComp";

function StockTaking() {
  return (
    <ParentComp
      title={"جرد المستودع"}
      prevPage={"نظام المخازن"}
      linkto={"/inventory"}
    >
      <StockTakingComp />
    </ParentComp>
  );
}

export default StockTaking;
