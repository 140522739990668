import ParentComp from "../ParentComp";
import EditTransactionsComp from "./EditTransactionsComp";

function EditTransactions() {
  return (
    <ParentComp
      title={"تعديل القيد"}
      prevPage={"القيود اليومية"}
      linkto={"/accounting/ledgerEntryeis"}
    >
      <EditTransactionsComp />
    </ParentComp>
  );
}

export default EditTransactions;
