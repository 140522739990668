import ParentComp from "../ParentComp";
import TransactionsComp from "./TransactionsComp";

function Transactions() {
  return (
    <ParentComp
      title={"قيد جديد"}
      prevPage={"القيود اليومية"}
      linkto={"/accounting/ledgerEntryeis"}
    >
      <TransactionsComp />
    </ParentComp>
  );
}

export default Transactions;
