import React, { useState, useEffect, useContext } from "react";

import DashboardDiv from "../subcomponent/DashboardDiv";

import { AuthCountext } from "../AuthCountext";

const InventorySystemComp = () => {
  const { theme } = useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);

  const list = [
    {
      name: "warehouses",
      title: "اعداد المخازن",
      link: "/Inventory/warehouses",
    },
    {
      name: "categories",
      title: "الفئات",
      link: "/Inventory/categories",
    },
    {
      name: "units",
      title: "الوحدات",
      link: "/Inventory/units",
    },
    {
      name: "items",
      title: "الأصناف",
      link: "/Inventory/items",
    },
    {
      name: "itemsunits",
      title: "وحدات الأصناف",
      link: "/Inventory/itemsunits",
    },
    {
      name: "suppliers",
      title: "الموردين",
      link: "/Inventory/suppliers",
    },
    {
      name: "buyers",
      title: "العملاء",
      link: "/Inventory/buyers",
    },
    {
      name: "suppliers",
      title: "الوارد",
      link: "/Inventory/PurchaseOrders",
    },
    {
      name: "invoices",
      title: "الصادر",
      link: "/Inventory/invoices",
    },
    {
      name: "StockTaking",
      title: "جرد",
      link: "/Inventory/StockTaking",
    },
    {
      name: "inventoryReports",
      title: "التقارير",
      link: "/Inventory/inventoryReports",
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className={`flex flex-column color-${theme} gap-10 full-width`}>
      <DashboardDiv list={list} isloading={isloading} />
    </div>
  );
};

export default InventorySystemComp;
