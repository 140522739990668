import { Button } from "antd";
import ParentComp from "../ParentComp";
import WarehousesComp from "./WarehousesComp";
import { useState } from "react";

function Warehouses() {
  const [openModal, setOpenModal] = useState(false);
  const ParentButton = () => {
    return (
      <Button type="primary" onClick={() => setOpenModal(true)}>
        إضافة مستودع
      </Button>
    );
  };

  
  return (
    <ParentComp
      title={"اعداد المخازن"}
      prevPage={"نظام المخازن"}
      linkto={"/inventory"}
      button={<ParentButton />}
    >
      <WarehousesComp openModal={openModal} setOpenModal={setOpenModal} />
    </ParentComp>
  );
}

export default Warehouses;
