import React, { useState, useEffect, useContext } from "react";
import { Input, Pagination, Space } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { AuthCountext } from "../AuthCountext";
import CutPlaceHolder from "./CutPlaceHolder";
import Button from "./Button";
import AlertComp from "./AlertComp";

const MainDocsComp = () => {
  const { SERVER_URL, theme, userToken } = useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);
  const [data, setData] = useState([]);
  const [disable, setDisable] = useState(false);
  // const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);
  const [deleletedId, setDeleletedId] = useState(null);
  const [isRefresh, setIsRefresh] = useState(false);
  const [disabledAria, setDisabledAria] = useState(false);
  const [editedId, setEditedId] = useState(null);
  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [total, setTotal] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataInPage, setDataInPage] = useState(7);

  // const [addButtonLoading, setAddButtonLoading] = useState(false);

  const [formData, setFormData] = useState(null);

  // fetch

  const fetchData = async () => {
    setIsloading(true);
    try {
      const response = await fetch(
        `${SERVER_URL}/maindocs/${currentPage}/${dataInPage}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      console.log(json);
      if (json.msg !== "No data") {
        if (json.data.length > 0) {
          setData(json.data);
          setTotal(json.total);
        }
      } else {
        setData([]);
      }
      loader();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh, SERVER_URL, currentPage, dataInPage]);

  // end fetch

  // insert data

  const addData = async () => {
    // setAddButtonLoading(true);
    setDisable(true);
    try {
      const response = await fetch(`${SERVER_URL}/maindocs`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify({
          title: formData,
        }),
      });
      const json = await response.json();
      console.log(json);
      setIsAlert(true);
      setAlertMsg("تمت اضافة السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      fetchData();
      // setAddButtonLoading(false);
      setDisable(false);
      setFormData(null);
    } catch (error) {
      console.error(error);
    }
  };

  const validationFun = () => {
    if (formData !== null) {
      addData();
      setDisable(true);
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
    }
  };

  function handleSubmit(event) {
    event.preventDefault();
    validationFun();
  }

  // end insert data

  // delete
  const deleteData = async (id) => {
    setDeleletedId(id);
    // setDeleteButtonLoading(true);
    try {
      const response = await fetch(`${SERVER_URL}/maindocs/${id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      setIsRefresh(!isRefresh);
      setDeleletedId(null);
      setIsAlert(true);
      setAlertMsg("تم حذف السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      if (json.errore.errorInfo[0] === "23000") {
        setIsAlert(true);
        setAlertMsg("لا يمكنك حذف السجل لارتباطه بسجلات اخرى.");
        setAlertName("alert-danger");
      }
      alertShow();
    } catch (error) {
      console.error(error);
    }
  };

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  // end delete

  // edit
  const [editFormData, setEditFormData] = useState({
    id: null,
    title: null
  });

  const editData = async (id) => {
    setEditedId(id);
    // setDeleteButtonLoading(true);
    try {
      const response = await fetch(`${SERVER_URL}/maindocs/`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify(editFormData),
      });
      const json = await response.json();
      console.log(json);
      setIsAlert(true);
      setAlertMsg("تم تعديل السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      setDisabledAria(false);
      setEditedId(null);
      setIsRefresh(!isRefresh);
      // setDeleteButtonLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const editValidationFun = () => {
    if (editFormData.id !== null && editFormData.title !== null ) {
      editData(editFormData.id);
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
    }
  };
  function handleEditSubmit(event) {
    event.preventDefault();
    editValidationFun();
    // setDisabledAria(false);
  }
  // function handleEditChange(event) {
  //   const { name, value, type, checked } = event.target;
  //   setEditFormData((prevEditFormData) => {
  //     return {
  //       ...prevEditFormData,
  //       [name]: type === "checkbox" ? checked : value,
  //     };
  //   });
  // }
  function handleEdit(x, y) {
    setEditedId(x.id);
    setEditFormData(x);
    setDisabledAria(y);
  }

  // end edit

  // others

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  const Place = () => {
    const places = [];
    for (let i = 0; i < dataInPage; i++) {
      places.push(<CutPlaceHolder />);
    }
    return places;
  };

  return (
    <div
      className={`${
        isloading
          ? "flex flex-row justify-center align-center "
          : " justify-space-between "
      } flex flex-column justify-center media-flex-culomn align-flex-start shadow-${theme} full-width-media border-radius text-align-center background-div-${theme} full-width min-height-300 p-20`}
      style={{ fontSize: "small" }}
    >
      <div className="full-width" style={{ minHeight: "580px" }}>
        <div className="flex flex-row justify-space-between align-center gap-10">
          {isAlert && <AlertComp msg={alertMsg} alert={alertName} />}
          <form
            className="flex flex-row justify-space-between align-center gap-20 media-flex-column"
            onSubmit={handleSubmit}
          >
            <Input
              type="text"
              name="title"
              className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
              placeholder="اسم المستند"
              onChange={(e) =>
                setFormData(e.target.value)
              }
            />

            <Button
              button={"add"}
              disable={disable}
              title={"إضافة"}
              type="primary"
              onClickMethod={handleSubmit}
              danger={false}
            />
          </form>
        </div>
       <div
          className={`over-flow-scroll over-flow-scroll-${theme}`}
          style={{ overflow: "scroll", width: "100%", minHeight: "500px" }}
        >
          <ul
            className={`border-bottom-${theme}-3 font-bold`}
            style={{ minWidth: "900px", width: "100%" }}
          >
            <li className="flex flex-row align-center justify-start width-5-100">
              الرقم
            </li>
            <li className="flex flex-row align-center justify-start width-40-100">
              الاسم
            </li>
            <li className="flex flex-row align-center justify-start width-10">
              عدد الموظفين
            </li>
            <li className="flex flex-row align-center justify-start width-10">
              تعديل
            </li>
            <li className="flex flex-row align-center justify-start width-10">
              حذف
            </li>
          </ul>
          {isloading ? (
            <Place />
          ) : data.length === 0 ? (
            <h1>لا توجد بيانات</h1>
          ) : (
            data?.map((item) => (
              <>
                {disabledAria && editedId === item.id ? (
                  <form onSubmit={handleEditSubmit}>
                    <ul
                      className={`border-bottom-${theme}-1`}
                      key={item.id}
                      style={{ minWidth: "900px", width: "100%" }}
                    >
                      <li className="flex flex-row align-center justify-start width-5-100">
                        {item.id}
                      </li>
                      <li className="flex flex-row align-center justify-start width-10">
                      <Input
                          type="text"
                          name="title"
                          className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                          placeholder="اسم المستند"
                          defaultValue={item.title}
                          onChange={(e) =>
                          setEditFormData((prevEditFormData) => {
                            return {
                              ...prevEditFormData,
                              title: e.target.value,
                            };
                          })
                        }
                        />
                        
                      </li>
                      <li className="flex flex-row align-center justify-start width-5-100">
                        {item.total}
                      </li>
                      <li className="flex flex-row align-center justify-start width-10">
                      <Button
                          button={"save"}
                          buttonId={editedId}
                          id={item.id}
                          disable={disable}
                          title={"حفظ"}
                          type="default"
                          onClickMethod={handleEditSubmit}
                          danger={false}
                          loading={isloading}
                        />
                      </li>
                      <li className="flex flex-row align-center justify-start width-10">
                        <Space>
                          <CloseCircleOutlined
                            className="font-24px transition font-hover "
                            onClick={(e) => setDisabledAria(false)}
                          />
                        </Space>
                      </li>
                    </ul>
                  </form>
                ) : (
                  <ul
                      className={`border-bottom-${theme}-1`}
                      key={item.id}
                      style={{ minWidth: "900px", width: "100%" }}
                    >
                    <li className="flex flex-row align-center justify-start width-5-100">
                      {item.id}
                    </li>
                    <li className="flex flex-row align-center justify-start width-40-100 gap-10">
                      <div>{item.title}</div>
                    </li>
                    <li className="flex flex-row align-center justify-start width-10">
                      {item.total}
                    </li>
                    <li className="flex flex-row align-center justify-start width-10 position-relative">
                    <Button
                        type="primary"
                        button="edit"
                        onClickMethod={() => handleEdit(item, true)}
                        buttonId={editedId}
                        id={item.id}
                        disable={disable}
                        title={"تعديل"}
                        danger={false}
                        loading={isloading}
                      />
                    </li>
                    <li className="flex flex-row align-center justify-start width-10 position-relative">
                      <Button
                        type="primary"
                        button="delete"
                        onClickMethod={() => deleteData(item.id, true)}
                        buttonId={deleletedId}
                        id={item.id}
                        disable={disable}
                        loading={isloading}
                        title={"حذف"}
                        danger={true}
                      />
                    </li>
                  </ul>
                )}
              </>
            ))
          )}
        </div>
      </div>

      <div className="flex flex-row p-20">
        <Pagination
          defaultCurrent={currentPage}
          total={total}
          pageSize={dataInPage}
          showQuickJumper
          showSizeChanger
          onChange={(page, pageSize) => {
            setDataInPage(pageSize);
            setCurrentPage(page);
          }}
        />
      </div>
    </div>
  );
};

export default MainDocsComp;
