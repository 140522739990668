import React, { createContext, useState, useEffect } from "react";
export const AuthCountext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [branchName, setBranchName] = useState(
    localStorage.getItem("branchName") || ""
  );
  const [branchId, setBranchId] = useState(
    localStorage.getItem("branchId") || ""
  );
  const [userToken, setUserToken] = useState(
    localStorage.getItem("userToken") || ""
  );
  const [userAdmin, setUserAdmin] = useState(
    localStorage.getItem("userAdmin") || ""
  );
  const [userName, setUserName] = useState(
    localStorage.getItem("userName") || ""
  );
  const [userAvatar, setUserAvatar] = useState(
    localStorage.getItem("userAvatar") || ""
  );
  const [usersData, setUsersData] = useState(
    localStorage.getItem("usersData") || true
  );
  const [branchData, setBranchData] = useState(
    localStorage.getItem("branchData") || true
  );
  const [userId, setUserId] = useState(localStorage.getItem("userId") || "");
  const [valid, setValid] = useState(null);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const [title, setTitle] = useState("نظام الرواتب");
  const [openRightBar, setOpenRightBar] = useState(false);

  // const SERVER_URL = 'http://gosayla.free.nf';
  const SERVER_URL = process.env.REACT_APP_HOMEURL;
  // const SERVER_URL = process.env.REACT_APP_LOCAL;
  const ACCOUNNTING_URL = process.env.REACT_APP_ACCOUNNTING;
  // const ACCOUNNTING_URL = process.env.REACT_APP_ACCOUNTINGLOCAL;


  useEffect(() => {
    const isUsers = async () => {
      const response = await fetch(`${SERVER_URL}/users/count`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      if (json.msg !== "No data") {
        localStorage.setItem("usersData", true);
        setUsersData(true);
      } else {
        setUsersData(false);
        console.log("No users");
      }
    };
    isUsers();
  }, [SERVER_URL, userToken, usersData]);

  useEffect(() => {
    const isBranch = async () => {
      const response = await fetch(`${SERVER_URL}/branch/count`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      if (json.msg !== "No data") {
        setBranchData(true);
        localStorage.setItem("branchData", true);
      } else {
        setBranchData(false);
        localStorage.setItem("branchData", false);
        console.log("No branch");
      }
    };
    isBranch();
  }, [SERVER_URL, branchData, userToken]);

  useEffect(() => {
    document.title = title;
    localStorage.setItem("theme", theme);
  }, [theme, title]);

  const login = async (formData) => {
    setIsLoading(true);
    // setIsLoading(true);
    const response = await fetch(`${SERVER_URL}/login`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    const json = await response.json();
    console.log("auth", json);
    if (json.msg !== "No data") {
      setUserName(json[0].name);
      setUserAvatar(json[0].avatar);
      setUserToken(json[0].token);
      setUserAdmin(json[0].admin);
      setUserId(json[0].id);
      localStorage.setItem("userName", json[0].name);
      localStorage.setItem("userToken", json[0].token);
      localStorage.setItem("userAvatar", json[0].avatar);
      localStorage.setItem("userAdmin", json[0].admin);
      localStorage.setItem("userId", json[0].id);
    } else {
      setValid("اسم المستخدم او كلمة المرور غير صحيحة");
    }
    setIsLoading(false);
    alertShow();
  };

  const alertShow = () => {
    const timer = setTimeout(() => setValid(null), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  const logout = () => {
    setIsLoading(true);
    setUserToken(null);
    setUserAvatar(null);
    setUserAdmin(null);
    setUserName(null);
    setBranchName(null);
    setBranchId(null);
    setUserId(null);
    localStorage.removeItem("userName");
    localStorage.removeItem("userToken");
    localStorage.removeItem("userAdmin");
    localStorage.removeItem("userAvatar");
    localStorage.removeItem("branchName");
    localStorage.removeItem("branchId");
    localStorage.removeItem("userId");
    setIsLoading(false);
  };

  const isLoggedIn = () => {
    try {
      let userToken = localStorage.getItem("userToken");
      let userAdmin = localStorage.getItem("userAdmin");
      let userNm = localStorage.getItem("userName");
      let avatar = localStorage.getItem("userAvatar");
      let userId = localStorage.getItem("userId");

      setIsLoading(true);
      setUserToken(userToken);
      setUserAdmin(userAdmin);
      setIsLoading(false);
      setUserName(userNm);
      setUserAvatar(avatar);
      setUserId(userId);
    } catch (error) {
      console.log(error);
    }
  };

  const isBranch = () => {
    try {
      let branchName = localStorage.getItem("branchName");
      let branchId = localStorage.getItem("branchId");
      setBranchId(branchId);
      setBranchName(branchName);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    isLoggedIn();
    isBranch();
  }, []);

  const setBranch = (id, branch) => {
    setBranchId(id);
    setBranchName(branch);
    localStorage.setItem("branchName", branch);
    localStorage.setItem("branchId", id);
  };

  return (
    <AuthCountext.Provider
      value={{
        login,
        logout,
        isLoading,
        userToken,
        valid,
        userAdmin,
        userName,
        userAvatar,
        setBranch,
        branchName,
        branchId,
        SERVER_URL,
        ACCOUNNTING_URL,
        theme,
        setTheme,
        title,
        setTitle,
        usersData,
        setUsersData,
        branchData,
        setBranchData,
        openRightBar,
        setOpenRightBar,
        userId,
      }}
    >
      {children}
    </AuthCountext.Provider>
  );
};
