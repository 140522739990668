import React, { useState, useEffect, useContext } from "react";
import { Select, Table, Typography, Button, DatePicker } from "antd";

import { AuthCountext } from "../AuthCountext";

import Column from "antd/es/table/Column";

import jsPDF from "jspdf";
import { callAddFont } from "../accounting/assets/font/Amiri-Regular-normal";
import { FilePdfOutlined } from "@ant-design/icons";
import { createPdf } from "./PdfReport";
import FetchInventory from "./FetchInventoryData";

jsPDF.API.events.push(["addFonts", callAddFont]);

const InventoryReportsComp = () => {
  const { theme, ACCOUNNTING_URL, userToken, branchId, SERVER_URL, userName } =
    useContext(AuthCountext);
  const { RangePicker } = DatePicker;
  const [isloading, setIsloading] = useState(true);
  const warehouses = FetchInventory().warehouses;
  const itemsList = FetchInventory().items;
  const [pdfLoadingButton, setPdfLoadingButton] = useState(false);
  const [data, setData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [logo, setLogo] = useState();
  const [reportType, setReportType] = useState("");

  const { Text } = Typography;
  const [formData, setFormData] = useState({
    branchId: branchId,
    reportType: reportType,
    WarehouseID: null,
    start: null,
    end: null,
  });

  const fetchData = async () => {
    setData([]);
    if (
      reportType !== "getCurrentStock" &&
      reportType !== "getLowStock" &&
      reportType !== "getInventoryValuation"
        ? formData.start !== null &&
          formData.end !== null &&
          formData.reportType !== null &&
          formData.WarehouseID !== null
        : reportType === 'getItemInventoryMovements' ? formData?.ItemID !== null : formData.reportType !== null && formData.WarehouseID !== null
    ) {
      setIsloading(true);
      try {
        const response = await fetch(`${ACCOUNNTING_URL}/inventoryreports`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
          body: JSON.stringify(formData),
        });
        const json = await response.json();

        if (json.length > 0) {
          setData(json);
        } else {
          setData([]);
        }
        loader();
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ACCOUNNTING_URL,
    reportType,
    formData.start,
    formData.end,
    formData.WarehouseID,
    formData?.ItemID
  ]);

  const options = [
    {
      value: "getCurrentStock",
      label: "تقرير المواد",
      pageDirction: "p",
    },
    {
      value: "getInventoryMovements",
      label: "تقرير حركة المخزون",
      pageDirction: "l",
    },
    {
      value: "getItemInventoryMovements",
      label: "تقرير حركة صنف",
      pageDirction: "l",
    },
    {
      value: "getInventoryValuation",
      label: "تقرير القيمة المخزنية",
      pageDirction: "p",
    },
    {
      value: "getSalesAndPurchase",
      label: "تقرير المبيعات والمشتروات",
      pageDirction: "p",
    },
    {
      value: "getLowStock",
      label: "تقرير انخفاض المخزون",
      pageDirction: "p",
    },
    {
      value: "getPurchaseReport",
      label: "تقرير المشتروات",
      pageDirction: "p",
    },
    {
      value: "getSalesReport",
      label: "تقرير المبيعات - الصادر",
      pageDirction: "p",
    },
    {
      value: "getTopSellingItems",
      label: "تقرير الأكثر مبيعا",
      pageDirction: "p",
    },
  ];
  // end fetch

  function handleSubmit(event) {
    event.preventDefault();
  }

  const fetchBranch = async () => {
    setIsloading(true);
    try {
      const response = await fetch(`${SERVER_URL}/branch/${branchId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      if (json.length > 0) {
        setBranchData(json[0]);
        setIsloading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchBranch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchId]);

  // creat pdf

  // get logo for report
  const getImage = async () => {
    setIsloading(true);
    try {
      const response = await fetch(`${SERVER_URL}/imgbase64/${branchId}/img`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      setLogo(json);
      loader();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // report columns
  var columns = [];
  switch (reportType) {
    case "getCurrentStock":
      columns = [
        { title: "القطاعي", dataKey: "RetailStock" },
        { title: "الجملة", dataKey: "WholesaleStock" },
        { title: "المستودع", dataKey: "WarehouseName" },
        { title: "اسم الصنف", dataKey: "ItemName" },
        { title: "رقم الصنف", dataKey: "ItemID" },
      ];
      break;
    case "getSalesAndPurchase":
      columns = [
        { title: "مجموع المبيعات", dataKey: "InvoiceAmount" },
        { title: "مجموع المشتريات", dataKey: "PurchaseAmount" },
        { title: "المستودع", dataKey: "WarehouseName" },
        { title: "التاريخ", dataKey: "Date" },
        { title: "رقم", dataKey: "ID" },
      ];
      break;
    case "getPurchaseReport":
      columns = [
        { title: "اجمالي الطلبية", dataKey: "OrderTotal" },
        { title: "اجمالي الصنف", dataKey: "LineTotal" },
        { title: "سعر الوحدة", dataKey: "UnitPrice" },
        { title: "الكمية", dataKey: "Quantity" },
        { title: "الصنف", dataKey: "ItemName" },
        { title: "المورد", dataKey: "SupplierName" },
        { title: "التاريخ", dataKey: "OrderDate" },
        { title: "رقم الأمر", dataKey: "PurchaseOrderID" },
      ];
      break;
    case "getInventoryValuation":
      columns = [
        { title: "متوسط التكلفة", dataKey: "AverageCostPerUnit" },
        { title: "قيمة المخزون", dataKey: "InventoryValue" },
        { title: "الرصيد", dataKey: "CurrentStock" },
        { title: "اسم الصنف", dataKey: "ItemName" },
        { title: "رقم الصنف", dataKey: "ItemID" },
      ];
      break;
    case "getInventoryMovements":
      columns = [
        { title: "بواسطة", dataKey: "CreatedBy" },
        { title: "الكمية", dataKey: "Quantity" },
        { title: "نوع الحركة", dataKey: "MovementType" },
        { title: "الصنف", dataKey: "ItemName" },
        { title: "المستودع", dataKey: "WarehouseName" },
        { title: "المرجع", dataKey: "ReferenceID" },
        { title: "التاريخ", dataKey: "MovementDate" },
        { title: "رقم الحركة", dataKey: "MovementID" },
      ];
      break;
    case "getItemInventoryMovements":
      columns = [
        { title: "الرصيد", dataKey: "Balance" },
        { title: "الصادر", dataKey: "OutQuantity" },
        { title: "الوارد", dataKey: "InQuantity" },
        { title: "نوع الحركة", dataKey: "MovementType" },
        { title: "الصنف", dataKey: "ItemName" },
        { title: "التاريخ", dataKey: "MovementDate" },
        { title: "رقم الحركة", dataKey: "MovementID" },
      ];
      break;
    case "getSalesReport":
      columns = [
        { title: "اجمالي الطلبية", dataKey: "InvoiceTotal" },
        { title: "اجمالي الصنف", dataKey: "LineTotal" },
        { title: "سعر الوحدة", dataKey: "PricePerUnit" },
        { title: "الكمية", dataKey: "Quantity" },
        { title: "الوحدة", dataKey: "UnitName" },
        { title: "الصنف", dataKey: "ItemName" },
        { title: "العميل", dataKey: "BuyerName" },
        { title: "التاريخ", dataKey: "InvoiceDate" },
        { title: "رقم الفاتورة", dataKey: "InvoiceID" },
      ];
      break;
    case "getTopSellingItems":
      columns = [
        { title: "إجمالي الإيرادات", dataKey: "TotalRevenue" },
        { title: "الكمية", dataKey: "TotalQuantitySold" },
        { title: "الصنف", dataKey: "ItemName" },
        { title: "رقم الصنف", dataKey: "ItemID" },
      ];
      break;
    default:
      columns = [
        { title: "الرصيد", dataKey: "CurrentStock" },
        { title: "المستودع", dataKey: "WarehouseName" },
        { title: "اسم الصنف", dataKey: "ItemName" },
        { title: "رقم الصنف", dataKey: "ItemID" },
      ];
      break;
  }

  const pdfLoder = () => {
    const timer = setTimeout(() => setPdfLoadingButton(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  // fetch

  // others

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  console.log(formData);
  return (
    <div
      className={`${
        isloading
          ? "flex flex-row justify-center align-center"
          : " justify-space-between "
      } flex flex-column justify-center media-flex-culomn align-flex-start shadow-${theme} full-width-media border-radius text-align-center background-div-${theme} full-width min-height-300 p-20`}
      style={{ fontSize: "small" }}
    >
      <div className="full-width" style={{ minHeight: "100px" }}>
        <div className="flex flex-row justify-space-between align-center gap-10">
          <form
            className="flex flex-row flex-wrap justify-start align-start gap-20 media-flex-column full-width"
            onSubmit={handleSubmit}
            style={{ marginBlock: "10px" }}
          >
            <div className="flex flex-column gap-5 align-start">
              <span>اختر التقرير</span>
              <Select
                name="reportType"
                showSearch
                className={`height-50 border-radius`}
                style={{ width: "300px" }}
                onChange={(e) => {
                  setReportType(e);
                  setFormData((prev) => {
                    return {
                      ...prev,
                      reportType: e,
                    };
                  });
                }}
                optionFilterProp="label"
                placeholder={isloading ? "جارٍ التحميل" : "اختر نوع التقرير"}
                options={options}
              />
            </div>
            <div className="flex flex-column gap-5 align-start">
              <span>اختر المستودع</span>
              <Select
                name="WarehouseID"
                showSearch
                className={`height-50 border-radius`}
                style={{ width: "300px" }}
                onChange={(e) => {
                  setFormData((prev) => {
                    return {
                      ...prev,
                      WarehouseID: e,
                    };
                  });
                }}
                optionFilterProp="label"
                placeholder={isloading ? "جارٍ التحميل" : "اختر المستودع"}
                options={warehouses?.map((item) => ({
                  value: item.WarehouseID,
                  label: item.WarehouseName,
                }))}
              />
            </div>

            {reportType !== "getCurrentStock" &&
              reportType !== "getLowStock" &&
              reportType !== "getInventoryValuation" && (
                <div className="flex flex-column gap-5 align-start">
                  <span>الفترة</span>
                  <RangePicker
                    onChange={(date, dateString) =>
                      setFormData((prevFormData) => {
                        return {
                          ...prevFormData,
                          start: dateString[0],
                          end: dateString[1],
                        };
                      })
                    }
                    style={{
                      minWidth: "200px",
                      maxWidth: "300px",
                      height: "50px",
                      borderRadius: "10px",
                    }}
                    size="large"
                    placeholder={["بداية الفترة", "نهاية الفترة"]}
                  />
                </div>
              )}
            {reportType === "getItemInventoryMovements" && (
              <div className="flex flex-column gap-5 align-start">
                <span>اختر الصنف</span>
                <Select
                  name="ItemID"
                  showSearch
                  className={`height-50 border-radius`}
                  style={{ width: "300px" }}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        ItemID: e,
                      };
                    });
                  }}
                  optionFilterProp="label"
                  placeholder={isloading ? "جارٍ التحميل" : "اختر الصنف"}
                  options={itemsList?.map((item) => ({
                    value: item.ItemID,
                    label: item.Name,
                  }))}
                />
              </div>
            )}
            <Button
              type="primary"
              loading={pdfLoadingButton}
              style={{ marginRight: "auto" }}
              disabled={data.length === 0}
              onClick={() => {
                setPdfLoadingButton(true);
                createPdf(
                  data,
                  columns,
                  null,
                  null,
                  branchData,
                  userName,
                  logo,
                  false,
                  reportType,
                  options
                );
                pdfLoder();
              }}
              size="medium"
              icon={<FilePdfOutlined />}
            >
              تنزيل
            </Button>
          </form>
        </div>
      </div>

      <Table
        dataSource={data && data}
        direction="ltr"
        style={{ width: "100%" }}
        pagination={{
          pageSize: 10,
        }}
        loading={isloading}
        bordered={true}
        footer={(currentPageData) => {
          return (
            <>
              <h3>{reportType}</h3>
            </>
          );
        }}
        filtered={true}
        size="small"
        summary={(pageData) => {
          let CurrentStockTotal = 0;
          let InventoryValueTotal = 0;

          data.forEach(
            ({ CurrentStock, InventoryValue, AverageCostPerUnit }) => {
              CurrentStockTotal += parseFloat(CurrentStock);
              InventoryValueTotal += parseFloat(InventoryValue);
            }
          );
          return (
            CurrentStockTotal > 0 && (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={2} index={0}>
                    اجمالي الكميات
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={1}>
                    <Text>
                      {Math.round(
                        (parseFloat(CurrentStockTotal) + Number.EPSILON) * 100
                      ) / 100}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    colSpan={2}
                    index={0}
                  ></Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={3} index={0}>
                    اجمالي القيمة
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={1}>
                    <Text>
                      {Math.round(
                        (parseFloat(InventoryValueTotal) + Number.EPSILON) * 100
                      ) / 100}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            )
          );
        }}
      >
        {columns.toReversed().map((item) => (
          <Column
            title={item.title}
            dataIndex={item.dataKey}
            render={(text) => {
              return text >= 0 || text <= 0
                ? Math.round((parseFloat(text) + Number.EPSILON) * 100) / 100
                : text;
            }}
          />
        ))}
      </Table>
    </div>
  );
};

export default InventoryReportsComp;
