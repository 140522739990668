import { InputNumber } from "antd";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AuthCountext } from "../AuthCountext";

function GetInvoiceItemPrice({
  ItemID,
  WarehouseID,
  item,
  setFormData,
  index,
}) {
  const { ACCOUNNTING_URL, userToken } = useContext(AuthCountext);
  const [data, setData] = useState(1);
  const fetchItemsUnits = async () => {
    if (ItemID !== null) {
      try {
        const response = await fetch(
          `${ACCOUNNTING_URL}/getItemPrice/${ItemID}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-api-key": userToken,
            },
          }
        );
        const json = await response.json();
        if (json.length > 0) {
          setData(json[0].PricePerUnit ? json[0].PricePerUnit : 1);
          setFormData((draft) => {
            draft.invoicedetails[index].PricePerUnit =
              Math.round(
                (json[0].PricePerUnit /
                  draft.invoicedetails[index].ConversionRate +
                  Number.EPSILON) *
                  100
              ) / 100;

            draft.invoicedetails[index].Vat =
              Math.round(
                ((json[0].PricePerUnit /
                  draft.invoicedetails[index].ConversionRate) *
                  0.15 *
                  draft.invoicedetails[index].Quantity +
                  Number.EPSILON) *
                  100
              ) / 100;
            draft.invoicedetails[index].TotalPrice =
              Math.round(
                ((json[0].PricePerUnit /
                  draft.invoicedetails[index].ConversionRate) *
                  draft.invoicedetails[index].Quantity +
                  draft.invoicedetails[index].Vat +
                  Number.EPSILON) *
                  100
              ) / 100;
          });
        } else {
          setData([]);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchItemsUnits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ItemID, item.UnitID]);

  const roundFunction = useCallback((x) => {
    return Math.round((x + Number.EPSILON) * 100) / 100;
  }, []);

  return (
    <InputNumber
      min={roundFunction(data / item.ConversionRate)}
      size="large"
      addonAfter={
        <span style={{ fontSize: "small" }}>
          {roundFunction(data / item.ConversionRate)}
        </span>
      }
      placeholder="سعر الوحدة"
      value={item.PricePerUnit}
      name="PricePerUnit"
      className={`height-40 full-width`}
      onChange={(e) => {
        setFormData((draft) => {
          draft.invoicedetails[index].PricePerUnit = e;
          draft.invoicedetails[index].Vat = roundFunction(
            e * 0.15 * draft.invoicedetails[index].Quantity
          );
          draft.invoicedetails[index].TotalPrice = roundFunction(
            e * draft.invoicedetails[index].Quantity +
              draft.invoicedetails[index].Vat
          );
        });
      }}
    />
  );
}

export default memo(GetInvoiceItemPrice);
