import React, { useState, useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { Button, Input } from "antd";
import { AuthCountext } from "../AuthCountext";

import FetchInventory from "./FetchInventoryData";

import StockTrackUi from "./StockTrackUi";

import jsPDF from "jspdf";
import { callAddFont } from "../accounting/assets/font/Amiri-Regular-normal";
import { FilePdfOutlined } from "@ant-design/icons";
import { createPdf } from "./PdfReport";

jsPDF.API.events.push(["addFonts", callAddFont]);

const StockTakingComp = () => {
  const { theme, ACCOUNNTING_URL, userToken, branchId, SERVER_URL, userName } =
    useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);
  const warehouses = FetchInventory().warehouses;
  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [type, setType] = useState("inStock");
  const [pdfLoadingButton, setPdfLoadingButton] = useState(false);
  const [branchData, setBranchData] = useState([]);
  const [logo, setLogo] = useState();

  const reportType = "getStockTrak";
  const [formData, setFormData] = useImmer({
    CreatedBy: userToken,
    WarehouseID: null,
    AdjustInventory: 0,
    StockTakingDetails: [],
  });

  const presetFormData = {
    CreatedBy: userToken,
    WarehouseID: null,
    AdjustInventory: 0,
    StockTakingDetails: [],
  };

  const handleAddfield = (index) => {
    setFormData((draft) => {
      draft.StockTakingDetails = [
        ...draft.StockTakingDetails,
        {
          ItemID: null,
          CountedQuantity: null,
          ExpectedQuantity: null,
          ReasonForDifference: 0,
        },
      ];
    });
    validStockTakingDetails();
  };

  const getSum = (x) => {
    let sum = 0;
    for (let i = 0; i < formData.StockTakingDetails.length; i++) {
      const element = formData.StockTakingDetails[i][x];
      sum += parseFloat(element);
    }
    return sum;
  };

  // fetch

  const fetchstockData = async () => {
    try {
      const response = await fetch(
        `${ACCOUNNTING_URL}/inventoryStockData/${branchId}/${formData.WarehouseID}/${type}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      if (json.length > 0) {
        setFormData((draft) => {
          draft.StockTakingDetails = json;
        });
      } else {
        setFormData((draft) => {
          draft.StockTakingDetails = [];
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchstockData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, formData.WarehouseID]);

  // end fetch

  // insert data

  const addData = async () => {
    // setDisable(true);
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/StockTaking`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify(formData),
      });
      const json = await response.json();
      console.log(json);
      setIsAlert(true);
      setAlertMsg("تمت اضافة السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
    } catch (error) {
      console.error(error);
    }
  };

  const validStockTakingDetails = () => {
    let x = 0;
    for (let index = 0; index < formData.StockTakingDetails.length; index++) {
      const item = formData.StockTakingDetails[index];

      x += item.ItemID !== null ? 1 : 0;
    }
    if (x === formData.StockTakingDetails.length) {
      return true;
    } else {
      return false;
    }
  };

  const validationFun = () => {
    if (
      formData.company_id !== null &&
      formData.InvoiceDate !== null &&
      formData.BuyerID !== null &&
      getSum("ReasonForDifference") + getSum("TotalPrice") !== 0 &&
      validStockTakingDetails()
    ) {
      addData();
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
      setDisableSaveButton(false);
    }
  };

  function handleSubmit(event) {
    setDisableSaveButton(true);
    event.preventDefault();
    validationFun();
  }

  // end insert data

  // others

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  loader();

  // pdf generator

  const fetchBranch = async () => {
    setIsloading(true);
    try {
      const response = await fetch(`${SERVER_URL}/branch/${branchId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      if (json.length > 0) {
        setBranchData(json[0]);
        setIsloading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchBranch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchId]);

  // get logo for report
  const getImage = async () => {
    setIsloading(true);
    try {
      const response = await fetch(`${SERVER_URL}/imgbase64/${branchId}/img`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      setLogo(json);
      loader();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = [
    {
      value: "getStockTrak",
      label: "جرد المستودع",
      pageDirction: "p",
    },
  ];

  const pdfLoder = () => {
    const timer = setTimeout(() => setPdfLoadingButton(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  // pdf genrator End

  const columns = [
    {
      title: "الفئة",
      dataIndex: "CategoryName",
      dataKey: "CategoryName",
    },
    {
      title: "الصنف",
      dataIndex: "Name",
      dataKey: "Name",
    },
    {
      title: "الكمية المتوقعة",
      dataIndex: "ExpectedQuantity",
      dataKey: "ExpectedQuantity",
    },
    {
      title: "الكمية الفعلية",
      dataIndex: "CountedQuantity",
      render: (_, item, index) => (
        <div>
          <Input
            defaultValue={item.CountedQuantity}
            type="text"
            name="CountedQuantity"
            style={{ minWidth: 70 }}
            className={`height-40 border-radius background-div-${theme} color-${theme}`}
            placeholder="الكمية الفعلية"
            onChange={(e) => {
              setFormData((draft) => {
                draft.StockTakingDetails[index].CountedQuantity =
                  e.target.value;
              });
            }}
          />
        </div>
      ),
    },
    {
      title: "الفارق",
      dataIndex: "QuantityDifference",
      dataKey: "QuantityDifference",
      render: (_, item, index) => (
        <span>{item.ExpectedQuantity - item.CountedQuantity}</span>
      ),
    },
    {
      title: "السبب",
      dataIndex: "ReasonForDifference",
      dataKey: "ReasonForDifference",
      render: (_, item, index) => (
        <Input
          type="text"
          name="ReasonForDifference"
          style={{ minWidth: 100 }}
          defaultValue={item.ReasonForDifference}
          disabled={item.ExpectedQuantity - item.CountedQuantity === 0}
          onChange={(e) => {
            setFormData((draft) => {
              draft.StockTakingDetails[index].ReasonForDifference =
                e.target.value;
            });
          }}
        />
      ),
    },
  ];

  return (
    <StockTrackUi
      title={"جرد المستودع"}
      columns={columns}
      formData={formData}
      setFormData={setFormData}
      handleAddfield={handleAddfield}
      handleSubmit={handleSubmit}
      isAlert={isAlert}
      alertMsg={alertMsg}
      alertName={alertName}
      isloading={isloading}
      list={warehouses}
      disableSaveButton={disableSaveButton}
      setDisableSaveButton={setDisableSaveButton}
      presetFormData={presetFormData}
      setType={setType}
      setIsLoading={setIsloading}
      pdf={
        <Button
          type="primary"
          loading={pdfLoadingButton}
          style={{ marginRight: "auto" }}
          disabled={formData.StockTakingDetails.length === 0}
          onClick={() => {
            setPdfLoadingButton(true);
            createPdf(
              formData.StockTakingDetails,
              columns.reverse(),
              null,
              null,
              branchData,
              userName,
              logo,
              false,
              reportType,
              options
            );
            pdfLoder();
          }}
          size="medium"
          icon={<FilePdfOutlined />}
        >
          تنزيل كشف الجرد
        </Button>
      }
    />
  );
};

export default StockTakingComp;
