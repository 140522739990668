import ParentComp from "../ParentComp";
import AccountingSystemComp from "./AccountingSystemComp";


function AccountingSystem() {
  return (
    <>
      <ParentComp title={"لوحة الإعدادات"}>
        <AccountingSystemComp />
      </ParentComp>
    </>
  );
}

export default AccountingSystem;