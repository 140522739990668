import React, { useState, useEffect, useContext } from "react";
import { Input, Pagination, Space, Button as Bt } from "antd";
import { CloseCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { AuthCountext } from "../AuthCountext";
import UploadImage from "./UploadImage";
import Image from "../../assets/images/Image";
import { profileDark, uploadphoto } from "../../assets/icons";
import CutPlaceHolder from "./CutPlaceHolder";
import Button from "./Button";
import AlertComp from "./AlertComp";

const BranchComp = () => {
  const { theme, SERVER_URL, setBranchData, userToken } =
    useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);
  const [data, setData] = useState([]);
  const [disable, setDisable] = useState(false);
  // const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);
  const [deleletedId, setDeleletedId] = useState(null);
  const [isRefresh, setIsRefresh] = useState(false);
  const [disabledAria, setDisabledAria] = useState(false);
  const [editedId, setEditedId] = useState(null);
  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [total, setTotal] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataInPage, setDataInPage] = useState(7);

  // const [addButtonLoading, setAddButtonLoading] = useState(false);

  const [formData, setFormData] = useState({
    title: null,
    logo: null,
  });

  // fetch

  const fetchData = async () => {
    setIsloading(true);
    try {
      const response = await fetch(
        `${SERVER_URL}/branch/${currentPage}/${dataInPage}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      console.log(json)
      if (json.msg !== "No data") {
        if (json.data.length > 0) {
          setData(json.data);
          setTotal(json.total);
        }
      } else {
        setData([]);
      }
      loader();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh, SERVER_URL, currentPage, dataInPage]);

  // end fetch

  // insert data

  const addData = async () => {
    setDisable(true);
    try {
      const response = await fetch(`${SERVER_URL}/branch`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify(formData),
      });
      const json = await response.json();
      console.log(json);
      setIsAlert(true);
      setAlertMsg("تمت اضافة السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      fetchData();
      setDisable(false);
      setBranchData(true);
    } catch (error) {
      console.error(error);
    }
  };

  const validationFun = () => {
    if (formData.title !== null) {
      addData();
      setDisable(true);
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
    }
  };

  function handleSubmit(event) {
    event.preventDefault();
    validationFun();
  }

  // end insert data

  // delete
  const deleteData = async (id) => {
    setDeleletedId(id);
    try {
      const response = await fetch(`${SERVER_URL}/branch/${id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      setIsRefresh(!isRefresh);
      setDeleletedId(null);
      setIsAlert(true);
      setAlertMsg("تم حذف السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      if (json.errore.errorInfo[0] === "23000") {
        setIsAlert(true);
        setAlertMsg("لا يمكنك حذف السجل لارتباطه بسجلات اخرى.");
        setAlertName("alert-danger");
      }
      alertShow();
    } catch (error) {
      console.error(error);
    }
  };

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  // end delete

  // edit
  const [editFormData, setEditFormData] = useState({
    id: null,
    title: null,
    logo: null,
    entitle: null,
    address: null,
    enaddress: null,
    email: null,
    phone: null,
    crnumber: null,
    vatnumber: null,
  });

  console.log(editFormData)

  const editData = async (id) => {
    setEditedId(id);
    try {
      const response = await fetch(`${SERVER_URL}/branch/`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify(editFormData),
      });
      const json = await response.json();
      console.log(json);
      setIsAlert(true);
      setAlertMsg("تم تعديل السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      setDisabledAria(false);
      setEditedId(null);
      setIsRefresh(!isRefresh);
    } catch (error) {
      console.error(error);
    }
  };

  const editValidationFun = () => {
    if (editFormData.id !== null && editFormData.title !== null) {
      editData(editFormData.id);
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
    }
  };
  function handleEditSubmit(event) {
    event.preventDefault();
    editValidationFun();
    // setDisabledAria(false);
  }
  // function handleEditChange(event) {
  //   const { name, value, type, checked } = event.target;
  //   setEditFormData((prevEditFormData) => {
  //     return {
  //       ...prevEditFormData,
  //       [name]: type === "checkbox" ? checked : value,
  //     };
  //   });
  // }
  function handleEdit(x, y) {
    setEditedId(x.id);
    setEditFormData(x);
    setDisabledAria(y);
  }

  // end edit

  // others

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  const Place = () => {
    const places = [];
    for (let i = 0; i < dataInPage; i++) {
      places.push(<CutPlaceHolder />);
    }
    return places;
  };

  return (
    <div
      className={`${
        isloading
          ? "flex flex-row justify-center align-center "
          : " justify-space-between "
      } flex flex-column justify-center media-flex-culomn align-flex-start shadow-${theme} full-width-media border-radius text-align-center background-div-${theme} full-width min-height-300 p-20`}
      style={{ fontSize: "small" }}
    >
      <div className="full-width" style={{ minHeight: "580px" }}>
        <div className="flex flex-row justify-space-between align-center gap-10">
          {isAlert && <AlertComp msg={alertMsg} alert={alertName} />}
          <form
            className="flex flex-row flex-wrap justify-start align-center gap-20 media-flex-column"
            onSubmit={handleSubmit}
          >
            <Input
              type="text"
              name="title"
              className={`width-200  height-50 border-radius background-div-${theme} color-${theme}`}
              placeholder="اسم الفرع"
              onChange={(e) => {
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    title: e.target.value,
                  };
                });
              }}
            />

            <Input
              type="text"
              name="entitle"
              className={`width-200 height-50 border-radius background-div-${theme} color-${theme}`}
              placeholder="اسم الفرع بالانجليزي"
              onChange={(e) => {
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    entitle: e.target.value,
                  };
                });
              }}
            />

            <Input
              type="text"
              name="address"
              className={`width-200 height-50 border-radius background-div-${theme} color-${theme}`}
              placeholder="العنوان"
              onChange={(e) => {
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    address: e.target.value,
                  };
                });
              }}
            />

            <Input
              type="text"
              name="enaddress"
              className={`width-200 height-50 border-radius background-div-${theme} color-${theme}`}
              placeholder="العنوان بالانجليزي"
              onChange={(e) => {
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    enaddress: e.target.value,
                  };
                });
              }}
            />

            <Input
              type="text"
              name="email"
              className={`width-200 height-50 border-radius background-div-${theme} color-${theme}`}
              placeholder="الايميل"
              onChange={(e) => {
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    email: e.target.value,
                  };
                });
              }}
            />

            <Input
              type="text"
              name="phone"
              className={`width-200 height-50 border-radius background-div-${theme} color-${theme}`}
              placeholder="رقم الجوال"
              onChange={(e) => {
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    phone: e.target.value,
                  };
                });
              }}
            />

            <Input
              type="text"
              name="crnumber"
              className={`width-200 height-50 border-radius background-div-${theme} color-${theme}`}
              placeholder="السجل التجاري"
              onChange={(e) => {
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    crnumber: e.target.value,
                  };
                });
              }}
            />

            <Input
              type="text"
              name="vatnumber"
              className={`width-200 height-50 border-radius background-div-${theme} color-${theme}`}
              placeholder="الرقم الضريبي"
              onChange={(e) => {
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    vatnumber: e.target.value,
                  };
                });
              }}
            />

            <UploadImage
              // the name in the formData
              name="logo"
              // the formData.
              data={formData}
              // setFormData.
              setData={setFormData}
              // the placeholder you want to show in.
              placeholder={
                <Bt style={{ height: "50px" }} icon={<UploadOutlined />}>
                  حمل صورة الفرع
                </Bt>
              }
              // the image link witch stored in name attribute.
              image={formData.logo}
              // icon
              icon={theme === "dark" ? profileDark : uploadphoto}
            />

            <Button
              button={"add"}
              disable={disable}
              title={"إضافة"}
              type="primary"
              onClickMethod={handleSubmit}
              danger={false}
            />
          </form>
        </div>
        <div
          className={`over-flow-scroll over-flow-scroll-${theme}`}
          style={{ overflow: "scroll", width: "100%", minHeight: "500px" }}
        >
          <ul
            className={`border-bottom-${theme}-3 font-bold`}
            style={{ minWidth: "1840px", width: "100%" }}
          >
            <li
              className="flex flex-row align-center justify-start"
              style={{ minWidth: "40px" }}
            >
              الرقم
            </li>
            <li
              className="flex flex-row align-center justify-start"
              style={{ minWidth: "300px", padding: "5px" }}
            >
              الاسم
            </li>
            <li
              className="flex flex-row align-center justify-start"
              style={{ minWidth: "150px", padding: "5px" }}
            >
              الاسم بالانجليزي
            </li>
            <li
              className="flex flex-row align-center justify-start"
              style={{ minWidth: "150px", padding: "5px" }}
            >
              العنوان
            </li>
            <li
              className="flex flex-row align-center justify-start"
              style={{ minWidth: "150px", padding: "5px" }}
            >
              العنوان بالانجليزي
            </li>
            <li
              className="flex flex-row align-center justify-start"
              style={{ minWidth: "150px", padding: "5px" }}
            >
              الايميل
            </li>
            <li
              className="flex flex-row align-center justify-start"
              style={{ minWidth: "150px", padding: "5px" }}
            >
              رقم الجوال
            </li>
            <li
              className="flex flex-row align-center justify-start"
              style={{ minWidth: "150px", padding: "5px" }}
            >
              السجل التجاري
            </li>
            <li
              className="flex flex-row align-center justify-start"
              style={{ minWidth: "150px", padding: "5px" }}
            >
              الرقم الضريبي
            </li>
            <li
              className="flex flex-row align-center justify-start"
              style={{ minWidth: "150px", padding: "5px" }}
            >
              عدد الموظفين
            </li>
            <li
              className="flex flex-row align-center justify-start"
              style={{ minWidth: "150px", padding: "5px" }}
            >
              تعديل
            </li>
            <li
              className="flex flex-row align-center justify-start"
              style={{ minWidth: "150px", padding: "5px" }}
            >
              حذف
            </li>
          </ul>
          {isloading ? (
            <Place />
          ) : data.length === 0 ? (
            <h1>لا توجد بيانات</h1>
          ) : (
            data.map((item, index) => (
              <>
                {disabledAria && editedId === item.id ? (
                  <form onSubmit={handleEditSubmit} key={index}>
                    <ul
                      className={`border-bottom-${theme}-1`}
                      key={index}
                      style={{ minWidth: "1840px", width: "100%" }}
                    >
                      <li
                        className="flex flex-row align-center justify-start"
                        style={{ minWidth: "40px" }}
                      >
                        {item.id}
                      </li>
                      <li
                        className="flex flex-row align-center justify-space-between gap-20"
                        style={{ minWidth: "300px", padding: "5px" }}
                      >
                        <UploadImage
                          key={1}
                          // the name in the formData
                          name="logo"
                          // the formData.
                          data={editFormData}
                          // setFormData.
                          setData={setEditFormData}
                          // the placeholder you want to show in.
                          placeholder={
                            <Bt
                              style={{ height: "50px" }}
                              icon={<UploadOutlined />}
                            >
                              حمل صورة الفرع
                            </Bt>
                          }
                          // the image link witch stored in name attribute.
                          image={editFormData.logo}
                          // icon
                          icon={theme === "dark" ? profileDark : uploadphoto}
                          listType={false}
                        />

                        <Input
                          type="text"
                          name="title"
                          className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                          placeholder="اسم الفرع"
                          defaultValue={item.title}
                          onChange={(e) =>
                            setEditFormData((prevEdiFormData) => {
                              return {
                                ...prevEdiFormData,
                                title: e.target.value,
                              };
                            })
                          }
                        />
                      </li>
                      <li
                        className="flex flex-row align-center justify-start"
                        style={{ minWidth: "150px", padding: "5px" }}
                      >
                        <Input
                          type="text"
                          name="entitle"
                          className={`width-200 height-50 border-radius background-div-${theme} color-${theme}`}
                          placeholder="اسم الفرع بالانجليزي"
                          defaultValue={item.entitle}
                          onChange={(e) => {
                            setEditFormData((prevEditFormData) => {
                              return {
                                ...prevEditFormData,
                                entitle: e.target.value,
                              };
                            });
                          }}
                        />
                      </li>{" "}
                      <li
                        className="flex flex-row align-center justify-start"
                        style={{ minWidth: "150px", padding: "5px" }}
                      >
                        <Input
                          type="text"
                          name="address"
                          className={`width-200 height-50 border-radius background-div-${theme} color-${theme}`}
                          placeholder="العنوان"
                          defaultValue={item.address}
                          onChange={(e) => {
                            setEditFormData((prevEditFormData) => {
                              return {
                                ...prevEditFormData,
                                address: e.target.value,
                              };
                            });
                          }}
                        />
                      </li>{" "}
                      <li
                        className="flex flex-row align-center justify-start"
                        style={{ minWidth: "150px", padding: "5px" }}
                      >
                        <Input
                          type="text"
                          name="enaddress"
                          className={`width-200 height-50 border-radius background-div-${theme} color-${theme}`}
                          placeholder="العنوان بالانجليزي"
                          defaultValue={item.enaddress}
                          onChange={(e) => {
                            setEditFormData((prevEditFormData) => {
                              return {
                                ...prevEditFormData,
                                enaddress: e.target.value,
                              };
                            });
                          }}
                        />
                      </li>{" "}
                      <li
                        className="flex flex-row align-center justify-start"
                        style={{ minWidth: "150px", padding: "5px" }}
                      >
                        <Input
                          type="text"
                          name="email"
                          className={`width-200 height-50 border-radius background-div-${theme} color-${theme}`}
                          placeholder="الايميل"
                          defaultValue={item.email}
                          onChange={(e) => {
                            setEditFormData((prevEditFormData) => {
                              return {
                                ...prevEditFormData,
                                email: e.target.value,
                              };
                            });
                          }}
                        />
                      </li>{" "}
                      <li
                        className="flex flex-row align-center justify-start"
                        style={{ minWidth: "150px", padding: "5px" }}
                      >
                        <Input
                          type="text"
                          name="phone"
                          className={`width-200 height-50 border-radius background-div-${theme} color-${theme}`}
                          placeholder="رقم الجوال"
                          defaultValue={item.phone}
                          onChange={(e) => {
                            setEditFormData((prevEditFormData) => {
                              return {
                                ...prevEditFormData,
                                phone: e.target.value,
                              };
                            });
                          }}
                        />
                      </li>{" "}
                      <li
                        className="flex flex-row align-center justify-start"
                        style={{ minWidth: "150px", padding: "5px" }}
                      >
                        <Input
                          type="text"
                          name="crnumber"
                          className={`width-200 height-50 border-radius background-div-${theme} color-${theme}`}
                          placeholder="السجل التجاري"
                          defaultValue={item.crnumber}
                          onChange={(e) => {
                            setEditFormData((prevEditFormData) => {
                              return {
                                ...prevEditFormData,
                                crnumber: e.target.value,
                              };
                            });
                          }}
                        />
                      </li>{" "}
                      <li
                        className="flex flex-row align-center justify-start"
                        style={{ minWidth: "150px", padding: "5px" }}
                      >
                        <Input
                          type="text"
                          name="vatnumber"
                          className={`width-200 height-50 border-radius background-div-${theme} color-${theme}`}
                          placeholder="الرقم الضريبي"
                          defaultValue={item.vatnumber}
                          onChange={(e) => {
                            setEditFormData((prevEditFormData) => {
                              return {
                                ...prevEditFormData,
                                vatnumber: e.target.value,
                              };
                            });
                          }}
                        />
                      </li>{" "}
                      <li
                        className="flex flex-row align-center justify-start"
                        style={{ minWidth: "150px", padding: "5px" }}
                      >
                        {item.total}
                      </li>
                      <li
                        className="flex flex-row align-center justify-start"
                        style={{ minWidth: "150px", padding: "5px" }}
                      >
                        <Button
                          button={"save"}
                          buttonId={editedId}
                          id={item.id}
                          disable={disable}
                          title={"حفظ"}
                          type="default"
                          onClickMethod={handleEditSubmit}
                          danger={false}
                          loading={isloading}
                        />
                      </li>
                      <li
                        className="flex flex-row align-center justify-start"
                        style={{ minWidth: "150px", padding: "5px" }}
                      >
                        <Space>
                          <CloseCircleOutlined
                            className="font-24px transition font-hover "
                            onClick={(e) => setDisabledAria(false)}
                          />
                        </Space>
                      </li>
                    </ul>
                  </form>
                ) : (
                  <ul
                    className={`border-bottom-${theme}-1`}
                    key={index}
                    style={{ minWidth: "1840px", width: "100%" }}
                  >
                    <li
                      className="flex flex-row align-center justify-start"
                      style={{ minWidth: "40px" }}
                    >
                      {item.id}
                    </li>
                    <li
                      className="flex flex-row align-center justify-start gap-10"
                      style={{ minWidth: "300px", padding: "5px" }}
                    >
                      <div
                        className={`flex flex-row justify-start align-center round over-flow width-40 height-40 shadow-${theme}`}
                      >
                        <Image
                          image={`${SERVER_URL}/${item?.logo}`}
                          w={40}
                          h={40}
                          name={item?.name}
                        />
                      </div>
                      <div>{item.title}</div>
                    </li>
                    <li
                      className="flex flex-row align-center justify-start"
                      style={{ minWidth: "150px", padding: "5px" }}
                    >
                      {item.entitle}
                    </li>
                    <li
                      className="flex flex-row align-center justify-start"
                      style={{ minWidth: "150px", padding: "5px" }}
                    >
                      {item.address}
                    </li>
                    <li
                      className="flex flex-row align-center justify-start"
                      style={{ minWidth: "150px", padding: "5px" }}
                    >
                      {item.enaddress}
                    </li>
                    <li
                      className="flex flex-row align-center justify-start"
                      style={{ minWidth: "150px", padding: "5px" }}
                    >
                      {item.email}
                    </li>
                    <li
                      className="flex flex-row align-center justify-start"
                      style={{ minWidth: "150px", padding: "5px" }}
                    >
                      {item.phone}
                    </li>
                    <li
                      className="flex flex-row align-center justify-start"
                      style={{ minWidth: "150px", padding: "5px" }}
                    >
                      {item.crnumber}
                    </li>
                    <li
                      className="flex flex-row align-center justify-start"
                      style={{ minWidth: "150px", padding: "5px" }}
                    >
                      {item.vatnumber}
                    </li>
                    <li
                      className="flex flex-row align-center justify-start"
                      style={{ minWidth: "150px", padding: "5px" }}
                    >
                      {item.total}
                    </li>
                    <li
                      className="flex flex-row align-center justify-start position-relative"
                      style={{ minWidth: "150px" }}
                    >
                      <Button
                        type="primary"
                        button="edit"
                        onClickMethod={() => handleEdit(item, true)}
                        buttonId={editedId}
                        id={item.id}
                        disable={disable}
                        title={"تعديل"}
                        danger={false}
                        loading={isloading}
                      />
                    </li>
                    <li
                      className="flex flex-row align-center justify-start position-relative"
                      style={{ minWidth: "150px" }}
                    >
                      <Button
                        type="primary"
                        button="delete"
                        onClickMethod={() => deleteData(item.id, true)}
                        buttonId={deleletedId}
                        id={item.id}
                        disable={disable}
                        loading={isloading}
                        title={"حذف"}
                        danger={true}
                      />
                    </li>
                  </ul>
                )}
              </>
            ))
          )}
        </div>
      </div>

      <div className="flex flex-row p-20">
        <Pagination
          defaultCurrent={currentPage}
          total={total}
          pageSize={dataInPage}
          showQuickJumper
          showSizeChanger
          onChange={(page, pageSize) => {
            setDataInPage(pageSize);
            setCurrentPage(page);
          }}
        />
      </div>
    </div>
  );
};

export default BranchComp;
