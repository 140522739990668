import React, { useContext, useEffect, useState } from "react";
import { AuthCountext } from "../AuthCountext";
import AlertComp from "../subcomponent/AlertComp";
import { Button, Input, Select, Space } from "antd";

function StepFive({
  currentStep,
  stepLenght,
  setFormData,
  formData,
  setDisable,
}) {
  const { SERVER_URL, theme, userToken } = useContext(AuthCountext);
  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [saveButtonDisable, setSaveButtonDisable] = useState(false);
  const [addButtonLoading, setaddButtonLoading] = useState(false);
  const [payData, setPayData] = useState([]);
  const [ispending, setIspending] = useState(false);

  // send data to poisition record table

  const fetchPayMethod = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/paymethod/`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      setPayData(json);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPayMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationFun = () => {
    if (formData.employeeId !== null && formData.amount !== null) {
      setIspending(true);
      setSaveButtonDisable(true);
      setIsAlert(true);
      setaddButtonLoading(true);
      addButtonLoadingTime();
      setAlertMsg(`تم تجاوز الخطوة رقم ${currentStep + 1} بنجاح`);
      setAlertName("alert-success");
      alertShow();
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
    }
  };

  const addButtonLoadingTime = () => {
    const timer = setTimeout(
      () => [
        setaddButtonLoading(false),
        setIspending(false),
        setDisable(false),
      ],
      3000
    );

    return () => {
      clearTimeout(timer);
    };
  };

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  return (
    <>
      {isAlert && <AlertComp msg={alertMsg} alert={alertName} />}
      <div
        className="flex flex-row justify-space-between gap-20 full-width media-flex-column"
        style={{ marginBottom: "20px" }}
      >
        <div className="full-width flex flex-column align-center gap-20">
          <span
            className="font-large font-bold"
            style={{ alignSelf: "center" }}
          >
            الراتب
          </span>
          <Space
            direction="vertical"
            style={{ width: "70%" }}
            className="media-full-width"
          >
            <Select
              name="paymethodId"
              showSearch
              className={`full-width height-50 border-radius`}
              onChange={(e) =>
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    paymethodId: e,
                  };
                })
              }
              optionFilterProp="label"
              placeholder="طريقة صرف الراتب"
              options={payData.map((item) => ({
                value: item.id,
                label: item.title,
              }))}
            />

            <Input
              type="text"
              name="amount"
              className={`full-width height-50 border-radius place-holder background-div-${theme} color-${theme}`}
              placeholder="الراتب"
              onChange={(e) =>
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    amount: e.target.value,
                  };
                })
              }
              size="large"
              addonAfter={<span>ر.س</span>}
            />

            <Button
              style={{ marginBlock: "10px", width: "100px" }}
              type="primary"
              disabled={saveButtonDisable}
              onClick={() => validationFun()}
              className={`flex flex-row ${
                addButtonLoading ? `justify-space-between` : `justify-center`
              } align-center`}
              loading={addButtonLoading}
            >
              {ispending
                ? "جارٍ الحفظ"
                : saveButtonDisable
                ? "تم الحفظ"
                : "اضافة"}
            </Button>
          </Space>
        </div>
      </div>
    </>
  );
}

export default StepFive;
