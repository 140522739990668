import React, { useState, useEffect, useContext } from "react";
import { Select, Table, Typography, Button, DatePicker } from "antd";

import { AuthCountext } from "../AuthCountext";

import FetchAccount from "./FetchAccount";

import Column from "antd/es/table/Column";

import jsPDF from "jspdf";
import { callAddFont } from "./assets/font/Amiri-Regular-normal";
import { FilePdfOutlined } from "@ant-design/icons";
import { createPdf } from "./PdfReport";

jsPDF.API.events.push(["addFonts", callAddFont]);

const BalanceSheetComp = () => {
  const { theme, ACCOUNNTING_URL, userToken, branchId, SERVER_URL, userName } =
    useContext(AuthCountext);
  const { RangePicker } = DatePicker;
  const [isloading, setIsloading] = useState(true);
  const [pdfLoadingButton, setPdfLoadingButton] = useState(false);
  const [data, setData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [logo, setLogo] = useState();
  const financialPreiod = FetchAccount().financialPreiod;
  const [reportType, setReportType] = useState("");
  const [financialId, setFinancialId] = useState();

  const { Text } = Typography;
  const [formData, setFormData] = useState({
    branchId: branchId,
    financialId: financialId,
    reportType: reportType,
    start: null,
    end: null,
  });

  console.log(formData);

  const fetchData = async () => {
    setData([])
    if (
      reportType === "getPeriodBalanceSheet"
        ? formData.start !== null &&
          formData.end !== null &&
          formData.reportType !== null &&
          formData.financialId !== null
        : formData.reportType !== null && formData.financialId !== null
    ) {
      setIsloading(true);
      try {
        const response = await fetch(`${ACCOUNNTING_URL}/balance`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
          body: JSON.stringify(formData),
        });
        const json = await response.json();
        if (json.msg !== "No data") {
          if (json.length > 0) {
            setData(json);
          }
        } else {
          setData([]);
        }
        loader();
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ACCOUNNTING_URL, reportType, financialId, formData.start, formData.end]);

  const options = [
    {
      value: "balancesheet",
      label: "الميزانية",
      pageDirction: "p",
    },
    {
      value: "getPeriodBalanceSheet",
      label: "ميزانية فترة محددة",
      pageDirction: "l",
    },
    {
      value: "trialbalance",
      label: "ميزان المراجعة",
      pageDirction: "p",
    },
    {
      value: "IncomeStatement",
      label: "بيان الدخل",
      pageDirction: "p",
    },
    {
      value: "CashFlowStatement",
      label: "بيان التدفقات النقدية",
      pageDirction: "p",
    },
    {
      value: "AccountsPayableAging",
      label: "تقادم الحسابات المستحقة الدفع",
      pageDirction: "p",
    },
    {
      value: "AccountsReceivableAging",
      label: "تقادم الحسابات المدينة",
      pageDirction: "p",
    },
  ];
  // end fetch

  function handleSubmit(event) {
    event.preventDefault();
  }

  const fetchBranch = async () => {
    setIsloading(true);
    try {
      const response = await fetch(`${SERVER_URL}/branch/${branchId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      if (json.length > 0) {
        setBranchData(json[0]);
        setIsloading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchBranch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchId]);

  // creat pdf

  // get logo for report
  const getImage = async () => {
    setIsloading(true);
    try {
      const response = await fetch(`${SERVER_URL}/imgbase64/${branchId}/img`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      setLogo(json);
      loader();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // report columns
  var columns = [];
  switch (reportType) {
    case "trialbalance":
      columns = [
        { title: "دائن", dataKey: "total_credits" },
        { title: "مدين", dataKey: "total_debits" },
        { title: "الحساب", dataKey: "account_name" },
      ];
      break;
    case "IncomeStatement":
      columns = [
        { title: "الرصيد", dataKey: "balance" },
        { title: "الحساب", dataKey: "account_name" },
        { title: "فئة الحساب", dataKey: "Category" },
      ];
      break;
    case "CashFlowStatement":
      columns = [
        { title: "المبلغ", dataKey: "balance" },
        { title: "الوصف", dataKey: "Description" },
        { title: "نوع الحركة", dataKey: "EntryType" },
        { title: "رقم الحركة", dataKey: "TransactionID" },
        { title: "الحساب", dataKey: "account_name" },
      ];
      break;
    case "getPeriodBalanceSheet":
      columns = [
        { title: "رصيد نهاية المدة", dataKey: "total_balance" },
        { title: "مدين", dataKey: "debit_after_period" },
        { title: "دائن", dataKey: "credit_after_period" },
        { title: "مدين", dataKey: "debit_main_period" },
        { title: "دائن", dataKey: "credit_main_period" },
        { title: "مدين", dataKey: "debit_before_period" },
        { title: "دائن", dataKey: "credit_before_period" },
        { title: "الحساب", dataKey: "account_name" },
      ];
      break;
    case "AccountsPayableAging":
      columns = [
        { title: "المدة", dataKey: "DaysOutstanding" },
        { title: "دائن", dataKey: "total_credits" },
        { title: "الحساب", dataKey: "account_name" },
      ];
      break;
    case "AccountsReceivableAging":
      columns = [
        { title: "المدة", dataKey: "DaysOutstanding" },
        { title: "مدين", dataKey: "total_debits" },
        { title: "الحساب", dataKey: "account_name" },
      ];
      break;
    default:
      columns = [
        { title: "الرصيد", dataKey: "balance" },
        { title: "دائن", dataKey: "total_credits" },
        { title: "مدين", dataKey: "total_debits" },
        { title: "الحساب", dataKey: "account_name" },
      ];
      break;
  }

  const pdfLoder = () => {
    const timer = setTimeout(() => setPdfLoadingButton(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  // fetch

  // others

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  return (
    <div
      className={`${
        isloading
          ? "flex flex-row justify-center align-center "
          : " justify-space-between "
      } flex flex-column justify-center media-flex-culomn align-flex-start shadow-${theme} full-width-media border-radius text-align-center background-div-${theme} full-width min-height-300 p-20`}
      style={{ fontSize: "small" }}
    >
      <div className="full-width" style={{ minHeight: "100px" }}>
        <div className="flex flex-row justify-space-between align-center gap-10">
          <form
            className="flex flex-row flex-wrap justify-start align-start gap-20 media-flex-column full-width"
            onSubmit={handleSubmit}
            style={{ marginBlock: "10px" }}
          >
            <Select
              name="reportType"
              showSearch
              className={`height-50 border-radius`}
              style={{ width: "300px" }}
              onChange={(e) => {
                reportType === "getPeriodBalanceSheet" &&
                  setFormData((prev) => {
                    return {
                      ...prev,
                      start: null,
                      end: null,
                    };
                  });
                setReportType(e);
                setFormData((prev) => {
                  return {
                    ...prev,
                    reportType: e,
                  };
                });
              }}
              optionFilterProp="label"
              placeholder={isloading ? "جارٍ التحميل" : "اختر نوع التقرير"}
              options={options}
            />

            <Select
              name="financial_period"
              showSearch
              className={`height-50 border-radius`}
              style={{ width: "300px" }}
              onChange={(e) => {
                setFinancialId(e);
                setFormData((prev) => {
                  return {
                    ...prev,
                    financialId: e,
                  };
                });
              }}
              optionFilterProp="label"
              placeholder={isloading ? "جارٍ التحميل" : "اختر الفترة"}
              defaultValue={
                financialPreiod?.filter(
                  (item) => item.is_open === "1" || item.is_open === 1
                )[0]?.id
              }
              options={financialPreiod?.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            />
            {(reportType === "getPeriodBalanceSheet" ||
              reportType === "IncomeStatement") && (
              <RangePicker
                onChange={(date, dateString) =>
                  setFormData((prevFormData) => {
                    return {
                      ...prevFormData,
                      start: dateString[0],
                      end: dateString[1],
                    };
                  })
                }
                style={{
                  minWidth: "200px",
                  maxWidth: "300px",
                  height: "50px",
                  borderRadius: "10px",
                }}
                size="large"
                placeholder={["بداية الفترة", "نهاية الفترة"]}
              />
            )}
            <Button
              type="primary"
              loading={pdfLoadingButton}
              style={{ marginRight: "auto" }}
              disabled={data.length === 0}
              onClick={() => {
                setPdfLoadingButton(true);
                createPdf(
                  data,
                  columns,
                  null,
                  null,
                  branchData,
                  userName,
                  logo,
                  false,
                  reportType,
                  options
                );
                pdfLoder();
              }}
              size="medium"
              icon={<FilePdfOutlined />}
            >
              تنزيل
            </Button>
          </form>
        </div>
      </div>

      <Table
        dataSource={data}
        direction="ltr"
        style={{ width: "100%" }}
        pagination={{
          pageSize: 10,
        }}
        loading={isloading}
        bordered={true}
        footer={(currentPageData) => {
          return (
            <>
              <h3>{reportType}</h3>
            </>
          );
        }}
        filtered={true}
        size="small"
        summary={(pageData) => {
          let creditAmountTotal = 0;
          let debitAmountTotal = 0;
          let balanceTotal = 0;
          data.forEach(
            ({ total_credits, total_debits, balance, total_balance }) => {
              creditAmountTotal += parseFloat(total_credits);
              debitAmountTotal += parseFloat(total_debits);
              balanceTotal += parseFloat(balance || total_balance);
            }
          );
          return (
            <>
              <Table.Summary.Row>
                {(data[0]?.total_credits || data[0]?.total_debits) && (
                  <>
                    <Table.Summary.Cell index={0}>الإجمالي</Table.Summary.Cell>
                    {data[0].total_credits && (
                      <Table.Summary.Cell index={1}>
                        <Text type="danger">
                          {Math.round(
                            (parseFloat(creditAmountTotal) + Number.EPSILON) *
                              100
                          ) / 100}
                        </Text>
                      </Table.Summary.Cell>
                    )}
                    {data[0]?.total_debits && (
                      <Table.Summary.Cell index={2}>
                        <Text>
                          {Math.round(
                            (parseFloat(debitAmountTotal) + Number.EPSILON) *
                              100
                          ) / 100}
                        </Text>
                      </Table.Summary.Cell>
                    )}
                  </>
                )}
              </Table.Summary.Row>
              {((data[0]?.total_debits && data[0]?.total_credits) ||
                data[0]?.balance ||
                data[0]?.total_balance) && (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={0}>الرصيد</Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={2}>
                    <Text
                      type={
                        creditAmountTotal - debitAmountTotal <= 0 ||
                        balanceTotal < 0
                          ? "danger"
                          : "success"
                      }
                    >
                      {balanceTotal
                        ? Math.round(
                            ((parseFloat(balanceTotal) + Number.EPSILON) *
                              100) /
                              100
                          )
                        : Math.round(
                            ((parseFloat(creditAmountTotal - debitAmountTotal) +
                              Number.EPSILON) *
                              100) /
                              100
                          )}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )}
            </>
          );
        }}
      >
        {columns.toReversed().map((item) => (
          <Column
            title={item.title}
            dataIndex={item.dataKey}
            render={(text) => {
              return text >= 0 || text <= 0
                ? Math.round((parseFloat(text) + Number.EPSILON) * 100) / 100
                : text;
            }}
          />
        ))}
      </Table>
    </div>
  );
};

export default BalanceSheetComp;
