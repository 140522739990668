import React from "react";

function AlertComp({msg, alert}) {
  return (
    <div className={alert}>
      <span>{msg}</span>
    </div>
  );
}

export default AlertComp;
