import React, { useState } from 'react'
import ParentComp from '../ParentComp'
import LedgerEntryeisComp from './LedgerEntryeisComp'
import { Button } from 'antd';
import { Link } from 'react-router-dom';

function LedgerEntryeis() {
  const [openModal, setOpenModal] = useState(false);
  const ParentButton = () => {
    return (
      <Link to='/accounting/transactions'>
        <Button type="primary" >
        إضافة قيد
      </Button>
      </Link>
    );
  };
  return (
    <ParentComp
    title={"قيود اليومية"}
    prevPage={"النظام المحاسبي"}
    linkto={"/accounting"}
    button={<ParentButton />}
  >
    <LedgerEntryeisComp openModal={openModal} setOpenModal={setOpenModal} />
  </ParentComp>
  )
}

export default LedgerEntryeis
