import { Select } from "antd";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AuthCountext } from "../AuthCountext";

function GetItemsUnits({ ItemID, item, setFormData, index }) {
  const { ACCOUNNTING_URL, userToken } = useContext(AuthCountext);
  const [itemUnitsList, setItemUnitsList] = useState([]);
  const [isloading, setIsloading] = useState(false);

  const cache = {};

  const fetchItemsUnits = async () => {
    if (cache[ItemID]) {
      setItemUnitsList(cache[ItemID]);
      setIsloading(false);
      return;
    }
    setIsloading(true);
    try {
      const response = await fetch(
        `${ACCOUNNTING_URL}/getitemUnits/${ItemID}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      cache[ItemID] = json.length > 0 ? json : [];
      setItemUnitsList(cache[ItemID]);
    } catch (error) {
      console.error("Error fetching item units:", error);
    } finally {
      setIsloading(false);
    }
  };

  const handleChange = useCallback(
    (e) => {
      const selectedUnit = itemUnitsList.find((item) => item.UnitID === e);
      setFormData((draft) => {
        draft.invoicedetails[index].UnitID = e;
        draft.invoicedetails[index].ConversionRate =
          selectedUnit?.ConversionRate || 1;
      });
    },
    [itemUnitsList, setFormData, index]
  );

  useEffect(() => {
    if (ItemID) {
      fetchItemsUnits();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ItemID]);

  return (
    <Select
      value={item.UnitID}
      name="UnitID"
      showSearch
      className="height-40 border-radius full-width"
      onChange={handleChange}
      loading={isloading}
      optionFilterProp="label"
      placeholder={isloading ? "جارٍ التحميل..." : "اختر الوحدة"}
      options={itemUnitsList.map((unit) => ({
        value: unit.UnitID,
        label: unit.UnitName,
      }))}
    />
  );
}

export default memo(GetItemsUnits);
