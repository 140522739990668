import ParentComp from "../ParentComp";
import InventorySystemComp from "./InventorySystemComp";


function InventorySystem() {
  return (
    <>
      <ParentComp title={"لوحة الإعدادات"}>
        <InventorySystemComp />
      </ParentComp>
    </>
  );
}

export default InventorySystem;