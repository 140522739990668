import ParentComp from "../ParentComp";
import ShowTransactionsComp from "./ShowTransactionsComp";

function ShowTransactions() {
  return (
    <ParentComp
      title={"عرض القيد"}
      prevPage={"القيود اليومية"}
      linkto={"/accounting/ledgerEntryeis"}
    >
      <ShowTransactionsComp />
    </ParentComp>
  );
}

export default ShowTransactions;
