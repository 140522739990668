import React, { useState, useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { Input, Select, DatePicker } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { AuthCountext } from "../AuthCountext";
import Button from "../subcomponent/Button";
import FetchAccount from "./FetchAccount";
import AlertComp from "../subcomponent/AlertComp";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Loader from "../Loader";

const todyDate = new Date().toLocaleDateString();

dayjs.extend(customParseFormat);

const EditTransactionsComp = () => {
  const { theme, ACCOUNNTING_URL, userToken, branchId } =
    useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);
  const { id } = useParams();
  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const accountsList = FetchAccount().data;
  const [data, setData] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);

  const [formData, setFormData] = useImmer({
    updated_by: userToken,
    updated_at: todyDate,
    description: data[0]?.description,
    date: data[0]?.date,
    ledger: [
      {
        id: null,
        account_id: null,
        entry_type: null,
        credit: 0,
        debit: 0,
      },
    ],
  });

  const fetchData = async () => {
    setIsloading(true);
    try {
      const response = await fetch(
        `${ACCOUNNTING_URL}/ledger_entries/${id}/${branchId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      if (json.msg !== "No data") {
        setData(json);
        setFormData((draft) => {
          draft.updated_by = userToken;
          draft.updated_at = todyDate;
          draft.description = json[0]?.description;
          draft.date = json[0]?.date;
          draft.ledger = json;
        });
        loader();
      } else {
        setData([]);
      }
      loader();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ACCOUNNTING_URL, isRefresh]);

  console.log(formData);
  console.log(data);

  const handleAddfield = (index) => {
    setFormData((draft) => {
      draft.ledger = [
        ...draft.ledger,
        {
          account_id: null,
          entry_type:
            index === 0 && formData.ledger[index].entry_type === "D"
              ? "C"
              : "D",
          credit: index === 0 ? formData.ledger[index].debit : 0,
          debit: index === 0 ? formData.ledger[index].credit : 0,
        },
      ];
    });
    validLedger();
  };

  const handleRemovefield = (e, index, id) => {
    deleteData(id);
    setFormData((draft) => {
      draft.ledger.splice(index, 1);
    });
  };

  const deleteData = async (id) => {
    try {
      const response = await fetch(
        `${ACCOUNNTING_URL}/ledger_entries/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      // setIsRefresh(!isRefresh);
      setIsAlert(true);
      setAlertMsg("تم حذف السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      if (json.errore.errorInfo[0] === "23000") {
        setIsAlert(true);
        setAlertMsg("لا يمكنك حذف السجل لارتباطه بسجلات اخرى.");
        setAlertName("alert-danger");
      }
      alertShow();
    } catch (error) {
      console.error(error);
    }
  };

  const getSum = (x) => {
    let sum = 0;
    for (let i = 0; i < formData.ledger.length; i++) {
      const element = formData.ledger[i][x];
      sum += parseFloat(element);
    }
    return sum;
  };

  // fetch

  // end fetch

  // insert data

  const addData = async () => {
    // setDisable(true);
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/transactions`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify(formData),
      });
      const json = await response.json();
      console.log(json);
      setIsAlert(true);
      setAlertMsg("تمت اضافة السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      setIsRefresh(!isRefresh);
    } catch (error) {
      console.error(error);
    }
  };

  const validLedger = () => {
    let x = 0;
    for (let index = 0; index < formData.ledger.length; index++) {
      const item = formData.ledger[index];

      x += item.account_id !== null ? 1 : 0;
    }
    if (x === formData.ledger.length) {
      return true;
    } else {
      return false;
    }
  };

  const validationFun = () => {
    if (
      formData.company_id !== null &&
      formData.date !== null &&
      getSum("credit") + getSum("debit") !== 0 &&
      validLedger()
    ) {
      addData();
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
    }
  };

  function handleSubmit(event) {
    event.preventDefault();
    validationFun();
  }

  // end insert data

  // others

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 2000);
    return () => {
      clearTimeout(timer);
    };
  };

  return (
    <div
      className={`
        flex flex-column justify-center media-flex-culomn align-center shadow-${theme} full-width-media border-radius text-align-center background-div-${theme} min-height-300 p-20`}
      style={{ fontSize: "small", width: "80%", minWidth: "500px" }}
    >
      {isAlert && <AlertComp msg={alertMsg} alert={alertName} />}
      <div
        className="flex flex-row justify-center align-start"
        style={{ minHeight: "580px" }}
      >
        <div className="flex flex-column gap-10 align-center">
          <h1 style={{ marginBottom: "40px" }}>تعديل قيد يومية</h1>
          {isloading ? (
            <Loader size="medium" />
          ) : (
            <form
              className="flex flex-column flex-wrap justify-center full-width align-start gap-20 media-flex-column"
              onSubmit={handleSubmit}
            >
              <div className="flex flex-row justify-space-between gap-10 full-width">
                <Input
                  type="text"
                  name="الوصف"
                  className={`height-50 border-radius background-div-${theme} color-${theme}`}
                  style={{ width: "50%" }}
                  placeholder="التفاصيل"
                  defaultValue={formData?.description}
                  onChange={(e) => {
                    setFormData((draft) => {
                      draft.description = e.target.value;
                    });
                  }}
                />
                <DatePicker
                  // defaultValue={dayjs(item.date, "YYYY-MM-DD")}
                  style={{
                    height: "50px",
                    borderRadius: "10px",
                    width: "30%",
                  }}
                  size={"large"}
                  placeholder="التاريخ"
                  defaultValue={dayjs(formData?.date, "YYYY-MM-DD")}
                  onChange={(date, dateString) =>
                    setFormData((prevFormData) => {
                      return {
                        ...prevFormData,
                        date: dateString,
                      };
                    })
                  }
                />
              </div>
              <div className="flex flex-column full-width gap-10 ">
                <div className="flex flex-row gap-10 justify-start align-center">
                  <div style={{ width: "40%" }}>الحساب</div>
                  <div style={{ width: "15%" }}>دائن</div>
                  <div style={{ width: "15%" }}>مدين</div>
                </div>
                {isloading ? (
                  <Loader size={"small"} />
                ) : (
                  formData.ledger?.map((item, index) => {
                    return (
                      <div
                        key={`${index}`}
                        className="flex flex-row gap-10 justify-start align-center"
                      >
                        <Select
                          value={item.account_id}
                          name="account_id"
                          showSearch
                          className={`height-50 border-radius`}
                          style={{ width: "40%" }}
                          onChange={(e) =>
                            setFormData((draft) => {
                              draft.ledger[index].account_id = e;
                            })
                          }
                          optionFilterProp="label"
                          placeholder={isloading ? "جارٍ التحميل" : "الحساب"}
                          options={accountsList.map((item) => ({
                            disabled:
                              item.is_parent === "1" || item.is_parent === 1
                                ? true
                                : false,
                            value: item.id,
                            label: `${item.account_id} ${item.account_name}`,
                          }))}
                        />
                        <Input
                          value={item.credit}
                          style={{ width: "15%" }}
                          type="text"
                          name="credit"
                          className={`width-200 height-50 border-radius background-div-${theme} color-${theme}`}
                          placeholder="دائن"
                          defaultValue={
                            item.entry_type === "C" ? item.amount : 0
                          }
                          onChange={(e) => {
                            setFormData((draft) => {
                              draft.ledger[index].credit = e.target.value;
                              draft.ledger[index].debit = 0;
                              draft.ledger[index].entry_type = "C";
                            });
                          }}
                        />
                        <Input
                          value={item.debit}
                          style={{ width: "15%" }}
                          type="text"
                          name="debit"
                          className={`width-200 height-50 border-radius background-div-${theme} color-${theme}`}
                          placeholder="مدين"
                          defaultValue={
                            item.entry_type === "D" ? item.amount : 0
                          }
                          onChange={(e) => {
                            setFormData((draft) => {
                              draft.ledger[index].debit = e.target.value;
                              draft.ledger[index].credit = 0;
                              draft.ledger[index].entry_type = "D";
                            });
                          }}
                        />
                        <div
                          className="flex justify-center align-center cursor-pointer plus"
                          onClick={() => handleAddfield(index)}
                        >
                          <PlusOutlined />
                        </div>

                        <div
                          className="flex justify-center align-center cursor-pointer minus"
                          onClick={(e) => handleRemovefield(e, index, item.id)}
                          style={{
                            display: formData.ledger.length === 1 && "none",
                          }}
                        >
                          <MinusOutlined className="cursor-pointer font-hover" />
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
              <Button
                button={"save"}
                disable={getSum("credit") === getSum("debit") ? false : true}
                title={"حفظ القيد"}
                type="primary"
                onClickMethod={handleSubmit}
                danger={false}
              />
              <div
                className="flex flex-row gap-10 justify-start align-center full-width border-radius height-50"
                style={{
                  background:
                    getSum("credit") === getSum("debit")
                      ? "#04AA6D"
                      : "#FF3E3E",
                  color: "#FAF9F6",
                }}
              >
                <div style={{ width: "40%" }}>
                  {getSum("credit") === getSum("debit")
                    ? "قيد متزن"
                    : "قيد غير متزن"}
                </div>
                <div style={{ width: "15%" }}>{getSum("credit")}</div>
                <div style={{ width: "15%" }}>{getSum("debit")}</div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditTransactionsComp;
