import ParentComp from "../ParentComp";
import PurchaseOrdersComp from "./PurchaseOrdersComp";

function PurchaseOrders() {
  return (
    <ParentComp
      title={"أمر شراء"}
      prevPage={"نظام المخازن"}
      linkto={"/inventory"}
    >
      <PurchaseOrdersComp />
    </ParentComp>
  );
}

export default PurchaseOrders;
