import { Button } from "antd";
import ParentComp from "../ParentComp";
import SuppliersComp from "./SuppliersComp";
import { useState } from "react";

function Suppliers() {
  const [openModal, setOpenModal] = useState(false);
  const ParentButton = () => {
    return (
      <Button type="primary" onClick={() => setOpenModal(true)}>
        إضافة مورد 
      </Button>
    );
  };

  
  return (
    <ParentComp
      title={"اعداد الموردين"}
      prevPage={"نظام المخازن"}
      linkto={"/inventory"}
      button={<ParentButton />}
    >
      <SuppliersComp openModal={openModal} setOpenModal={setOpenModal} />
    </ParentComp>
  );
}

export default Suppliers;
