import React, { memo, useContext } from "react";
import {
  Select,
  DatePicker,
  Form,
  Segmented,
  Statistic,
  Card,
  Table,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { AuthCountext } from "../AuthCountext";
import Button from "../subcomponent/Button";
import AlertComp from "../subcomponent/AlertComp";

const OrderComponent = ({
  columns,
  formData,
  setFormData,
  handleSubmit,
  isAlert,
  alertMsg,
  alertName,
  isloading,
  list,
  title,
  disableSaveButton,
  setDisableSaveButton,
  presetFormData,
  orderType,
  handleAddfield,
}) => {
  const { theme } = useContext(AuthCountext);


  return (
    <div
      className={`
        flex flex-column justify-center media-flex-culomn align-center shadow-${theme} full-width-media border-radius text-align-center background-div-${theme} min-height-300 p-20`}
      style={{ fontSize: "small", width: "100%", minWidth: "500px" }}
    >
      {isAlert && <AlertComp msg={alertMsg} alert={alertName} />}
      <div
        className="flex flex-row justify-center align-start full-width"
        style={{ minHeight: "580px" }}
      >
        <div
          className="flex flex-column gap-10 full-width media-flex-culomn"
          style={{ padding: "20px" }}
        >
          <h1>{title}</h1>
          <Form
            className="flex flex-column flex-wrap justify-center full-width align-start gap-20 media-flex-column"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-row full-width justify-space-between">
              <div className="flex flex-column gap-5 align-start">
                <span>حالة الطلبية</span>
                <Segmented
                  options={[
                    {
                      value: "Pending",
                      label: "قيد الانتظار",
                    },
                    {
                      value: "Completed",
                      label: "مكتملة",
                    },
                    {
                      value: "Cancelled",
                      label: "تم الإلغاء",
                    },
                  ]}
                  value={formData.Status}
                  onChange={(e) => {
                    setDisableSaveButton(false);
                    setFormData((prev) => {
                      return {
                        ...prev,
                        Status: e,
                      };
                    });
                  }}
                />
              </div>
              <div className="flex flex-column gap-5 align-start">
                <span>حالة الدفع</span>
                <Segmented
                  options={[
                    {
                      value: "Pending",
                      label: "قيد الانتظار",
                    },
                    {
                      value: "Paid",
                      label: "مدفوعة",
                    },
                  ]}
                  value={formData.PaymentStatus}
                  onChange={(e) => {
                    setDisableSaveButton(false);
                    setFormData((prev) => {
                      return {
                        ...prev,
                        PaymentStatus: e,
                      };
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row gap-10 full-width media-flex-culomn">
              <div
                className="flex flex-row gap-10 full-width media-flex-culomn"
                style={{ flexGrow: "1" }}
              >
                <div className="flex flex-column gap-5 align-start">
                  {orderType === "PurchaseOrder" ? (
                    <span>اختر المورد</span>
                  ) : (
                    <span>اختر العميل</span>
                  )}
                  {orderType === "PurchaseOrder" ? (
                    <Select
                      name={"SupplierID"}
                      showSearch
                      className={`height-50 border-radius`}
                      style={{ width: "300px" }}
                      value={formData.SupplierID}
                      onChange={(e) => {
                        setDisableSaveButton(false);
                        setFormData((prev) => {
                          return {
                            ...prev,
                            SupplierID: e,
                          };
                        });
                      }}
                      optionFilterProp="label"
                      placeholder={isloading ? "جارٍ التحميل" : "اختر المورد"}
                      options={list?.map((item) => ({
                        value: item.SupplierID,
                        label: item.Name,
                      }))}
                    />
                  ) : (
                    <Select
                      name={"BuyerID"}
                      showSearch
                      className={`height-50 border-radius`}
                      style={{ width: "300px" }}
                      value={formData.BuyerID}
                      onChange={(e) => {
                        console.log(formData);
                        setDisableSaveButton(false);
                        setFormData((prev) => {
                          return {
                            ...prev,
                            BuyerID: e,
                          };
                        });
                      }}
                      optionFilterProp="label"
                      placeholder={isloading ? "جارٍ التحميل" : "اختر العميل"}
                      options={list?.map((item) => ({
                        value: item.BuyerID,
                        label: item.Name,
                      }))}
                    />
                  )}
                </div>
                <div
                  className="flex flex-column gap-5 align-start"
                  style={{ minWidth: "200px", maxWidth: "300px" }}
                >
                  <span>التاريخ</span>
                  {orderType === "PurchaseOrder" ? (
                    <DatePicker
                      // defaultValue={dayjs(item.OrderDate, "YYYY-MM-DD")}
                      style={{
                        height: "50px",
                        borderRadius: "10px",
                        width: "100%",
                      }}
                      size={"large"}
                      placeholder="التاريخ"
                      defaultValue={formData.OrderDate}
                      onChange={(OrderDate, OrderDateString) =>
                        setFormData((prevFormData) => {
                          return {
                            ...prevFormData,
                            OrderDate: OrderDateString,
                          };
                        })
                      }
                    />
                  ) : (
                    <DatePicker
                      // defaultValue={dayjs(item.OrderDate, "YYYY-MM-DD")}
                      style={{
                        height: "50px",
                        borderRadius: "10px",
                        width: "100%",
                      }}
                      size={"large"}
                      placeholder="التاريخ"
                      defaultValue={formData.InvoiceDate}
                      onChange={(OrderDate, OrderDateString) =>
                        setFormData((prevFormData) => {
                          return {
                            ...prevFormData,
                            InvoiceDate: OrderDateString,
                          };
                        })
                      }
                    />
                  )}
                </div>
              </div>
              <div
                className="flex flex-row gap-10 justify-space-between media-flex-culomn"
                style={{ marginRight: "auto", minWidth: "200px" }}
              >
                <Card size="small" bordered={false}>
                  <Statistic
                    title="الضريبة"
                    value={
                      Math.round((formData?.TotalVat + Number.EPSILON) * 100) /
                      100
                    }
                  />
                </Card>
                <Card size="small" bordered={false}>
                  <Statistic
                    title="الإجمالي"
                    value={
                      Math.round(
                        (formData?.TotalAmount + Number.EPSILON) * 100
                      ) / 100
                    }
                  />
                </Card>
              </div>
            </div>
            <div
              className={`full-width over-flow-scroll over-flow-scroll-${theme} media-flex-culomn`}
            >
              <Table
                className="full-width"
                columns={columns}
                dataSource={formData.invoicedetails}
                pagination={false}
              />

              <Button onClickMethod={() => handleAddfield()} title={"أضف صنف"}>
                <PlusOutlined />
              </Button>
            </div>
            <div
              className="flex flex-row gap-10"
              style={{ marginRight: "auto" }}
            >
              {disableSaveButton && (
                <Button
                  button={"add"}
                  title={"قيد جديد"}
                  type="primary"
                  onClickMethod={() => {
                    setDisableSaveButton(false);
                    setFormData(presetFormData);
                  }}
                  danger={false}
                />
              )}
              <Button
                button={"save"}
                disable={disableSaveButton}
                title={"حفظ الأمر"}
                type="primary"
                onClickMethod={handleSubmit}
                danger={false}
              />
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default memo(OrderComponent);
