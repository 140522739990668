import React, { useContext, useState } from "react";
import {
  uploadphoto,
  uploadid,
  passport,
  uploadidDark,
  passportDark,
  profileDark,
} from "../../assets/icons/index";
import { AuthCountext } from "../AuthCountext";
import AlertComp from "../subcomponent/AlertComp";
import UploadImage from "../subcomponent/UploadImage";
import { Button, Space } from "antd";

function StepThree({
  currentStep,
  stepLenght,
  setFormData,
  formData,
  setDisable,
}) {
  const { theme } = useContext(AuthCountext);
  const [addButtonLoading, setAddButtonLoading] = useState(false);
  const [saveButtonDisable, setSaveButtonDisable] = useState(false);
  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [ispending, setIspending] = useState(false);

  const validationFun = () => {
    if (
      formData.photo !== null &&
      formData.idcopy !== null &&
      formData.passport !== null
    ) {
      // fetchData();
      setIspending(true);
      setSaveButtonDisable(true);
      setAddButtonLoading(true);
      addButtonLoadingTime();
      setIsAlert(true);
      setAlertMsg(`تم تجاوز الخطوة رقم ${currentStep + 1} بنجاح`);
      setAlertName("alert-success");
      alertShow();
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
    }
  };

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  const addButtonLoadingTime = () => {
    const timer = setTimeout(
      () => [
        setAddButtonLoading(false),
        setIspending(false),
        setDisable(false),
      ],
      3000
    );

    return () => {
      clearTimeout(timer);
    };
  };

  return (
    <>
      {isAlert && <AlertComp msg={alertMsg} alert={alertName} />}
      <div className="flex flex-row justify-space-between gap-20 full-width media-flex-column" style={{marginBottom: '20px'}}>
        <div className="full-width flex flex-column align-center gap-20">
          <span
            className="font-large font-bold"
            style={{ alignSelf: "center" }}
          >
            نسخة من المستندات الثبوتية
          </span>
          <Space
            direction="vertical"
            style={{ width: "70%" }}
            className="media-full-width"
          >
            <UploadImage
              // the name in the formData
              name="photo"
              // the formData.
              data={formData}
              // setFormData.
              setData={setFormData}
              // the placeholder you want to show in.
              placeholder={"حمل صورة شخصية"}
              // the image link witch stored in name attribute.
              image={formData.photo}
              // icon
              icon={theme === "dark" ? profileDark : uploadphoto}

              listType="picture-card"
            />

            <UploadImage
              // the name in the formData
              name="idcopy"
              // the formData.
              data={formData}
              // setFormData.
              setData={setFormData}
              // the placeholder you want to show in.
              placeholder={"حمل صورة الهوية"}
              // the image link witch stored in name attribute.
              image={formData.idcopy}
              // icon
              icon={theme === "dark" ? uploadidDark : uploadid}

              listType="picture-card"
            />
            <UploadImage
              // the name in the formData
              name="passport"
              // the formData.
              data={formData}
              // setFormData.
              setData={setFormData}
              // the placeholder you want to show in.
              placeholder={"حمل صورة الجواز"}
              // the image link witch stored in name attribute.
              image={formData.passport}
              // icon
              icon={theme === "dark" ? passportDark : passport}

              listType="picture-card"
            />

            {/* <button
            className={`save-button shadow-${theme} ${
              saveButtonDisable && "disabled"
            }`}
            disabled={saveButtonDisable}
            onClick={() => {
              setSaveButtonDisable(true);
              setDisable(false);
              setAddButtonLoading(true);
              addButtonLoadingTime();
              setIsAlert(true);
              setAlertMsg(`تم تجاوز الخطوة رقم ${currentStep + 1} بنجاح`);
              setAlertName("alert-success");
              alertShow();
            }}
          >
            {addButtonLoading && <Loader size={"small"} color={"white"} />}
            {saveButtonDisable ? "تم الحفظ" : "اضافة"}
          </button> */}
            <Button
              style={{ marginBlock: "10px", width: "100px" }}
              type="primary"
              disabled={saveButtonDisable}
              onClick={() => validationFun()}
              className={`flex flex-row ${
                addButtonLoading ? `justify-space-between` : `justify-center`
              } align-center`}
              loading={addButtonLoading}
            >
              {ispending
                ? "جارٍ الحفظ"
                : saveButtonDisable
                ? "تم الحفظ"
                : "اضافة"}
            </Button>
          </Space>
        </div>
      </div>
    </>
  );
}

export default StepThree;
