import React, { useState, useEffect, useContext } from "react";
import { Space, Select, Table, Typography, Button, DatePicker } from "antd";

import { AuthCountext } from "../AuthCountext";

import FetchAccount from "./FetchAccount";

import Column from "antd/es/table/Column";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import { callAddFont } from "./assets/font/Amiri-Regular-normal";
import { FilePdfOutlined } from "@ant-design/icons";
import { createPdf } from "./PdfReport";

jsPDF.API.events.push(["addFonts", callAddFont]);

const ReportComp = () => {
  const { theme, ACCOUNNTING_URL, userToken, branchId, SERVER_URL, userName } =
    useContext(AuthCountext);
  const { RangePicker } = DatePicker;
  const [isloading, setIsloading] = useState(true);
  const [pdfLoadingButton, setPdfLoadingButton] = useState(false);
  const [data, setData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [logo, setLogo] = useState();
  const accountsList = FetchAccount().data;
  const [account_id, setAccount_id] = useState("");
  const { Text } = Typography;
  const [formData, setFormData] = useState({
    id: null,
    startdate: null,
    enddate: null,
  });

  const fetchData = async () => {
    setIsloading(true);
    try {
      const response = await fetch(
        `${ACCOUNNTING_URL}/report/${branchId}/${account_id}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
          body: JSON.stringify(formData),
        }
      );
      const json = await response.json();
      if (json.msg !== "No data") {
        if (json.length > 0) {
          setData(json);
        }
      } else {
        setData([]);
      }
      loader();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ACCOUNNTING_URL, formData]);

  // end fetch

  function handleSubmit(event) {
    event.preventDefault();
  }

  const fetchBranch = async () => {
    setIsloading(true);
    try {
      const response = await fetch(`${SERVER_URL}/branch/${branchId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      if (json.length > 0) {
        setBranchData(json[0]);
        setIsloading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchBranch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchId]);

  
  // creat pdf

  // get logo for report
  const getImage = async () => {
    setIsloading(true);
    try {
      const response = await fetch(`${SERVER_URL}/imgbase64/${branchId}/img`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      setLogo(json);
      loader();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // report columns
  const columns = [
    { title: "الرصيد", dataKey: "balance" },
    { title: "دائن", dataKey: "CreditAmount" },
    { title: "مدين", dataKey: "DebitAmount" },
    { title: "البيان", dataKey: "description" },
    { title: "التاريخ", dataKey: "date" },
  ];

  const pdfLoder = () => {
    const timer = setTimeout(() => setPdfLoadingButton(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  // fetch

  // others

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  

  return (
    <div
      className={`${
        isloading
          ? "flex flex-row justify-center align-center "
          : " justify-space-between "
      } flex flex-column justify-center media-flex-culomn align-flex-start shadow-${theme} full-width-media border-radius text-align-center background-div-${theme} full-width min-height-300 p-20`}
      style={{ fontSize: "small" }}
    >
      <div className="full-width" style={{ minHeight: "100px" }}>
        <div className="flex flex-row justify-space-between align-center gap-10">
          <form
            className="flex flex-row flex-wrap justify-start align-start gap-20 media-flex-column full-width"
            onSubmit={handleSubmit}
            style={{ marginBlock: "10px" }}
          >
            <RangePicker
              onChange={(date, dateString) =>
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    startdate: dateString[0],
                    enddate: dateString[1],
                  };
                })
              }
              style={{
                width: "auto",
                height: "50px",
                borderRadius: "10px",
              }}
              size="large"
              placeholder={["بداية الفترة", "نهاية الفترة"]}
            />

            <Select
              name="account_id"
              showSearch
              className={`height-50 border-radius`}
              style={{ width: "300px" }}
              onChange={(e) => {
                setAccount_id(e);
                setFormData((prev) => {
                  return {
                    ...prev,
                    id: e,
                  };
                });
              }}
              optionFilterProp="label"
              placeholder={isloading ? "جارٍ التحميل" : "اختر حساب"}
              options={accountsList.map((item) => ({
                disabled:
                  item.is_parent === "0" ||
                  item.is_parent === 0 ||
                  item.is_parent === null
                    ? false
                    : true,
                value: item.account_id,
                label: `${item.account_id} - ${item.account_name}`,
              }))}
            />

            <Button
              type="primary"
              loading={pdfLoadingButton}
              style={{ marginRight: "auto" }}
              disabled={data.length === 0}
              onClick={() => {
                setPdfLoadingButton(true);
                createPdf(
                  data,
                  columns,
                  formData.startdate,
                  formData.enddate,
                  branchData,
                  userName,
                  logo,
                  true
                );
                pdfLoder();
              }}
              size="medium"
              icon={<FilePdfOutlined />}
            >
              تنزيل
            </Button>
          </form>
        </div>
      </div>

      <Table
        dataSource={data}
        style={{ width: "100%" }}
        pagination={{
          pageSize: 10,
        }}
        loading={isloading}
        bordered={true}
        footer={(currentPageData) => {
          console.log(currentPageData);
          return (
            <>
              <h3>{data[0]?.account_name}</h3>
            </>
          );
        }}
        filtered={true}
        size="small"
        summary={(pageData) => {
          let creditAmountTotal = 0;
          let debitAmountTotal = 0;
          data.forEach(({ CreditAmount, DebitAmount }) => {
            creditAmountTotal += parseFloat(CreditAmount);
            debitAmountTotal += parseFloat(DebitAmount);
          });
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={0}>الإجمالي</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <Text type="danger">
                    {Math.round(
                      (parseFloat(creditAmountTotal) + Number.EPSILON) * 100
                    ) / 100}
                  </Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <Text>
                    {Math.round(
                      (parseFloat(debitAmountTotal) + Number.EPSILON) * 100
                    ) / 100}
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={0}>
                  رصيد نهاية الفترة
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={2}>
                  <Text
                    type={
                      creditAmountTotal - debitAmountTotal < 0
                        ? "danger"
                        : "success"
                    }
                  >
                    {Math.round(
                      ((parseFloat(
                        creditAmountTotal -
                          debitAmountTotal +
                          parseFloat(
                            data[0]?.opening_balance === null
                              ? 0
                              : data[0]?.opening_balance
                          )
                      ) +
                        Number.EPSILON) *
                        100) /
                        100
                    )}
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      >
        <Column title="التاريخ" dataIndex="date" key="date" />
        <Column title="الحساب" dataIndex="account_name" key="account_name" />
        <Column title="البيان" dataIndex="description" key="description" />
        <Column
          title="دائن"
          dataIndex="CreditAmount"
          key="CreditAmount"
          render={(text) => {
            return (
              text > 0 &&
              Math.round((parseFloat(text) + Number.EPSILON) * 100) / 100
            );
          }}
        />
        <Column
          title="مدين"
          dataIndex="DebitAmount"
          key="DebitAmount"
          render={(text) => {
            return (
              text > 0 &&
              Math.round((parseFloat(text) + Number.EPSILON) * 100) / 100
            );
          }}
        />
        <Column
          title="الرصيد"
          dataIndex="balance"
          key="balance"
          render={(text) => {
            return (
              text &&
              Math.round((parseFloat(text) + Number.EPSILON) * 100) / 100
            );
          }}
        />

        <Column
          title="Action"
          key="action"
          render={(_, record) => (
            <Space size="middle">
              <Link to={`/accounting/edit-transactions/${record.id}`}>
                تعديل
              </Link>
              <Link to={`/accounting/show-transactions/${record.id}`}>عرض</Link>
            </Space>
          )}
        />
      </Table>
    </div>
  );
};

export default ReportComp;
