import { Button } from "antd";
import ParentComp from "../ParentComp";
import CategoriesComp from "./CategoriesComp";
import { useState } from "react";

function Categories() {
  const [openModal, setOpenModal] = useState(false);
  const ParentButton = () => {
    return (
      <Button type="primary" onClick={() => setOpenModal(true)}>
        إضافة فئة
      </Button>
    );
  };

  
  return (
    <ParentComp
      title={"اعداد الفئات"}
      prevPage={"نظام المخازن"}
      linkto={"/inventory"}
      button={<ParentButton />}
    >
      <CategoriesComp openModal={openModal} setOpenModal={setOpenModal} />
    </ParentComp>
  );
}

export default Categories;
