import ParentComp from "../ParentComp";
import ReportComp from "./ReportComp";

function Report() {
  return (
    <ParentComp
      title={"التقارير"}
      prevPage={"النظام المحاسبي"}
      linkto={"/accounting"}
    >
      <ReportComp />
    </ParentComp>
  );
}

export default Report;
