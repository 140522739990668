import { InputNumber } from "antd";
import React, { memo, useContext, useEffect, useState, useCallback } from "react";
import { AuthCountext } from "../AuthCountext";

const VAT_RATE = 0.15;

function GetItemPrice({ ItemID, WarehouseID, item, setFormData, index }) {
  const { ACCOUNNTING_URL, userToken } = useContext(AuthCountext);
  const [data, setData] = useState(1); // Default price

  // Helper functions
  const calculateVAT = useCallback((pricePerUnit, quantity) => {
    return Math.round(pricePerUnit * quantity * VAT_RATE * 100) / 100;
  }, []);

  const calculateTotalPrice = useCallback((pricePerUnit, quantity) => {
    const vat = calculateVAT(pricePerUnit, quantity);
    return Math.round((pricePerUnit * quantity + vat) * 100) / 100;
  }, [calculateVAT]);

  // Fetch item price
  const fetchItemPrice = useCallback(async () => {
    if (!ItemID) return;

    try {
      const response = await fetch(
        `${ACCOUNNTING_URL}/getitemprice/${ItemID}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );

      const json = await response.json();
      if (json.length > 0) {
        const pricePerUnit = json[0]?.PricePerUnit || 1;
        setData(pricePerUnit);

        // Initialize form data
        setFormData((draft) => {
          const adjustedPrice =
            pricePerUnit / (draft.invoicedetails[index].ConversionRate || 1);

          draft.invoicedetails[index] = {
            ...draft.invoicedetails[index],
            PricePerUnit: Math.round((adjustedPrice + Number.EPSILON) * 100) / 100,
            Vat: calculateVAT(adjustedPrice, draft.invoicedetails[index].Quantity),
            TotalPrice: calculateTotalPrice(
              adjustedPrice,
              draft.invoicedetails[index].Quantity
            ),
          };
        });
      } else {
        setData(1); // Reset to default if no data found
      }
    } catch (error) {
      console.error("Error fetching item price:", error);
    }
  }, [ItemID, ACCOUNNTING_URL, userToken, setFormData, calculateVAT, calculateTotalPrice, index]);

  // Handle price change
  const handlePriceChange = useCallback(
    (price) => {
      setFormData((draft) => {
        draft.invoicedetails[index].PricePerUnit = price;
        draft.invoicedetails[index].Vat = calculateVAT(
          price,
          draft.invoicedetails[index].Quantity
        );
        draft.invoicedetails[index].TotalPrice = calculateTotalPrice(
          price,
          draft.invoicedetails[index].Quantity
        );
      });
    },
    [setFormData, calculateVAT, calculateTotalPrice, index]
  );

  // Effect to fetch item price
  useEffect(() => {
    fetchItemPrice();
  }, [fetchItemPrice]);

  return (
    <InputNumber
      size="large"
      addonAfter={
        <span style={{ fontSize: "small" }}>
          {Math.round((data / item.ConversionRate + Number.EPSILON) * 100) / 100}
        </span>
      }
      placeholder="سعر الوحدة"
      value={item.PricePerUnit}
      name="PricePerUnit"
      className="height-40 full-width"
      onChange={handlePriceChange}
    />
  );
}

export default memo(GetItemPrice);
