/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { Pagination, Button } from "antd";

import { AuthCountext } from "../AuthCountext";

import CutPlaceHolder from "../subcomponent/CutPlaceHolder";

import { Link } from "react-router-dom";
import { DeleteOutlined, EditOutlined, LinkOutlined } from "@ant-design/icons";
import AlertComp from "../subcomponent/AlertComp";

const LedgerEntryeisComp = ({ openModal, setOpenModal }) => {
  const { theme, ACCOUNNTING_URL, userToken, branchId } =
    useContext(AuthCountext);

  const [isloading, setIsloading] = useState(true);
  const [data, setData] = useState([]);

  // const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);
  const [deleltedId, setDeleletedId] = useState(null);
  const [isRefresh, setIsRefresh] = useState(false);

  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [total, setTotal] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataInPage, setDataInPage] = useState(10);
  const [f_period, setF_period] = useState(1);

  // fetch

  const fetchData = async () => {
    setIsloading(true);
    try {
      const response = await fetch(
        `${ACCOUNNTING_URL}/ledger_entries/${currentPage}/${dataInPage}/${branchId}/${f_period}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      if (json.msg !== "No data") {
        if (json.data.length > 0) {
          setData(json.data);
          setTotal(json.total);
        }
      } else {
        setData([]);
      }
      loader();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh, ACCOUNNTING_URL, currentPage, dataInPage]);

  // end fetch

  // delete
  const deleteData = async (id) => {
    setDeleletedId(id);
    try {
      const response = await fetch(
        `${ACCOUNNTING_URL}/transactions/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      setIsRefresh(!isRefresh);
      setDeleletedId(null);
      setIsAlert(true);
      setAlertMsg("تم حذف السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      if (json.errore.errorInfo[0] === "23000") {
        setIsAlert(true);
        setAlertMsg("لا يمكنك حذف السجل لارتباطه بسجلات اخرى.");
        setAlertName("alert-danger");
      }
      alertShow();
    } catch (error) {
      console.error(error);
    }
  };

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  // end delete

  // others

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  const Place = () => {
    const places = [];
    for (let i = 0; i < dataInPage; i++) {
      places.push(<CutPlaceHolder />);
    }
    return places;
  };

  return (
    <div
      className={`${
        isloading
          ? "flex flex-row justify-center align-center "
          : " justify-space-between "
      } flex flex-column justify-center media-flex-culomn align-flex-start shadow-${theme} full-width-media border-radius text-align-center background-div-${theme} full-width min-height-300 p-20`}
      style={{ fontSize: "small" }}
    >
      <div className="full-width" style={{ minHeight: "580px" }}>
        <div className="flex flex-row justify-space-between align-center gap-10">
          {isAlert && <AlertComp msg={alertMsg} alert={alertName} />}
        </div>
        <div
          className={`over-flow-scroll over-flow-scroll-${theme}`}
          style={{ overflow: "scroll", width: "100%", minHeight: "500px" }}
        >
          <ul
            className={`border-bottom-${theme}-3 font-bold`}
            style={{ minWidth: "1100px", width: "100%" }}
          >
            <li
              className="flex flex-row align-center justify-start"
              style={{ minWidth: "100px", padding: "5px" }}
            >
              رقم العملية
            </li>
            <li
              className="flex flex-row align-center justify-start"
              style={{ minWidth: "100px" }}
            >
              تاريخ العملية
            </li>
            <li
              className="flex flex-row align-center justify-start"
              style={{ minWidth: "300px", padding: "5px" }}
            >
              الوصف
            </li>

            <li
              className="flex flex-row align-center justify-start"
              style={{ minWidth: "100px", padding: "5px" }}
            >
              مدين
            </li>
            <li
              className="flex flex-row align-center justify-start"
              style={{ minWidth: "100px", padding: "5px" }}
            >
              دائن
            </li>
            <li
              className="flex flex-row align-center justify-start"
              style={{ minWidth: "100px", padding: "5px" }}
            >
              متزن؟
            </li>

            <li
              className="flex flex-row align-center justify-space-between"
              style={{ minWidth: "300px", padding: "5px" }}
            >
              الاجراءات
            </li>
          </ul>
          {isloading ? (
            <Place />
          ) : data.length === 0 ? (
            <h1>لا توجد بيانات</h1>
          ) : (
            data.map((item, index) => (
              <>
                <ul
                  className={`border-bottom-${theme}-1`}
                  key={index}
                  style={{
                    minWidth: "1100px",
                    width: "100%",
                    paddingBlock: "10px",
                  }}
                >
                  <li
                    className="flex flex-row align-center justify-start"
                    style={{ minWidth: "100px", padding: "5px" }}
                  >
                    {item.transaction_id}
                  </li>
                  <li
                    className="flex flex-row align-center justify-start"
                    style={{ minWidth: "100px" }}
                  >
                    {item.date}
                  </li>
                  <li
                    className="flex flex-row align-center justify-start gap-10"
                    style={{ minWidth: "300px", padding: "5px" }}
                  >
                    {item.description}
                  </li>

                  <li
                    className="flex flex-row align-center justify-start"
                    style={{ minWidth: "100px", padding: "5px" }}
                  >
                    {item.Credit}
                  </li>
                  <li
                    className="flex flex-row align-center justify-start"
                    style={{ minWidth: "100px", padding: "5px" }}
                  >
                    {item.Debit}
                  </li>
                  <li className="flex flex-row justify-start align-start">
                    <span
                      style={{
                        minWidth: "50px",
                        padding: "5px",
                        textAlign: "center",
                        fontSize: '10px',
                        color:
                         'white',
                        borderRadius: 15,
                        background: item.Balance === "Balanced" ? '#069460' : 'tomato'
                      }}
                    >
                      {item.Balance === "Balanced" ? "متزن" : "غير متزن"}
                    </span>
                  </li>

                  <li
                    className="flex flex-row align-center justify-space-between position-relative"
                    style={{ minWidth: "300px" }}
                  >
                    <Link
                      to={`/accounting/show-transactions/${item.transaction_id}`}
                    >
                      <Button
                        type="link"
                        variant="dashed"
                        icon={<LinkOutlined />}
                      >
                        عرض
                      </Button>
                    </Link>
                    <Link
                      to={`/accounting/edit-transactions/${item.transaction_id}`}
                    >
                      <Button color="primary" icon={<EditOutlined />}>
                        تعديل
                      </Button>
                    </Link>

                    <Button
                      type="primary"
                      onClick={() => deleteData(item.transaction_id, true)}
                      danger={true}
                      icon={<DeleteOutlined />}
                      color="default"
                    >
                      حذف
                    </Button>
                  </li>
                </ul>
              </>
            ))
          )}
        </div>
      </div>

      <div className="flex flex-row p-20">
        <Pagination
          defaultCurrent={currentPage}
          total={total}
          pageSize={dataInPage}
          showQuickJumper
          showSizeChanger
          onChange={(page, pageSize) => {
            setDataInPage(pageSize);
            setCurrentPage(page);
          }}
        />
      </div>
    </div>
  );
};

export default LedgerEntryeisComp;
