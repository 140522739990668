import React, { useState, useEffect, useContext } from "react";
import { Modal, Input, Select, Checkbox, Tree } from "antd";

import { AuthCountext } from "../AuthCountext";

import FetchAccount from "./FetchAccount";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import AlertComp from "../subcomponent/AlertComp";

const TreeOfAccountsComp = ({
  openModal,
  setOpenModal,
  setAddAccountDisable,
}) => {
  const { theme, ACCOUNNTING_URL, setaccountData, userToken, branchId } =
    useContext(AuthCountext);
  const accountsList = FetchAccount().data;
  const accounttypes = FetchAccount().accounttypes;
  const [isloading, setIsloading] = useState(true);

  const [isRefresh, setIsRefresh] = useState(false);

  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmEditLoading, setConfirmEditLoading] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [tree, setTree] = useState([]);
  const [lastId, setLastId] = useState(null);

  // const [addButtonLoading, setAddButtonLoading] = useState(false);

  const [formData, setFormData] = useState({
    account_id: lastId,
    account_name: null,
    account_type_id: null,
    balance_type: null,
    is_parent: null,
    parent_id: null,
    company_id: branchId,
    created_by: userToken,
  });

  // fetch

  const fetchTree = async () => {
    setIsloading(true);
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/tree/`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      setTree(json.data);
      setIsloading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchLastId = async () => {
    try {
      const response = await fetch(
        `${ACCOUNNTING_URL}/tree/${formData.parent_id}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      console.log("last", json[0].newId);
      setLastId(json[0].newId);
      setFormData((prev) => {
        return {
          ...prev,
          account_id: json[0].newId,
        };
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchLastId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.parent_id]);

  useEffect(() => {
    fetchTree();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchId]);

  // end fetch

  // insert data

  const addData = async () => {
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/account`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify(formData),
      });
      const json = await response.json();
      console.log(json);
      setIsAlert(true);
      setAlertMsg("تمت اضافة السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      fetchTree();

      setaccountData(true);
    } catch (error) {
      console.error(error);
    }
  };

  const validationFun = () => {
    if (
      formData.account_id !== null ||
      formData.account_name !== null ||
      formData.account_type_id !== null ||
      formData.balance_type !== null
    ) {
      addData();

      setConfirmLoading(true);
      setTimeout(() => {
        setOpenModal(false);
        setConfirmLoading(false);
      }, 2000);
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
    }
  };

  // function handleSubmit(event) {
  //   // event.preventDefault();
  //   validationFun();
  // }

  // end insert data

  // delete

  const deleteData = async (id) => {
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/account/delete/${id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      setIsRefresh(!isRefresh);

      setIsAlert(true);
      setAlertMsg("تم حذف السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      if (json.errore.errorInfo[0] === "23000") {
        setIsAlert(true);
        setAlertMsg("لا يمكنك حذف السجل لارتباطه بسجلات اخرى.");
        setAlertName("alert-danger");
      }
      alertShow();
    } catch (error) {
      console.error(error);
    }
  };

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  // end delete

  // edit
  const [editFormData, setEditFormData] = useState({
    account_id: null,
    account_name: "",
    account_type_id: "",
    balance_type: "",
    is_parent: null,
    parent_id: null,
    company_id: branchId,
    updated_by: userToken,
    updated_at: new Date(),
  });

  const editData = async (id) => {
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/account/`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify(editFormData),
      });
      const json = await response.json();
      console.log(json);
      setIsAlert(true);
      setAlertMsg("تم تعديل السجل بنجاح");
      setAlertName("alert-success");
      alertShow();

      setIsRefresh(!isRefresh);
      setConfirmEditLoading(true);
      setTimeout(() => {
        setOpenEditModal(false);
        setConfirmEditLoading(false);
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  const editValidationFun = () => {
    if (editFormData.id !== null && editFormData.title !== null) {
      editData(editFormData.id);
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      setOpenEditModal(false);
      alertShow();
    }
  };
  // function handleEditSubmit(event) {
  //   event.preventDefault();
  //   editValidationFun();
  //   // setDisabledAria(false);
  // }

  function handleEdit(x, y) {
    setEditFormData((prev) => {
      return {
        ...prev,
        id: x.id,
        created_at: x.created_at,
        created_by: x.created_by,
        account_id: x.account_id,
        account_name: x.account_name,
        account_type_id: x.account_type_id,
        balance_type: x.balance_type,
        is_parent: x.is_parent,
        parent_id: x.parent_id,
        is_archived: x.is_archived,
        company_id: branchId,
        updated_by: userToken,
      };
    });
    setOpenEditModal(true);
  }

  function handleEditCancel() {
    setEditFormData(null);
    setOpenEditModal(false);
    setConfirmEditLoading(false);
  }

  // end edit

  // others

  return (
    <div
      className={`${
        isloading
          ? "flex flex-row justify-center align-center "
          : " justify-space-between "
      } flex flex-column justify-center media-flex-culomn align-flex-start shadow-${theme} full-width-media border-radius text-align-center background-div-${theme} full-width min-height-300 p-20`}
      style={{ fontSize: "small" }}
    >
      <div className="full-width" style={{ minHeight: "580px" }}>
        <div className="flex flex-row justify-space-between align-center gap-10">
          {isAlert && <AlertComp msg={alertMsg} alert={alertName} />}

          <Modal
            key={1}
            title="إضافة حساب"
            open={openModal}
            onOk={validationFun}
            confirmLoading={confirmLoading}
            onCancel={() => setOpenModal(false)}
            destroyOnClose={true}
          >
            <form
              className="flex flex-column justify-center align-center gap-20 media-flex-column full-width"
              style={{ padding: "20px" }}
            >
              {isAlert && <div className={`${alertName}`}>{alertMsg}</div>}
              <div className="full-width">
                <span>أصل الحساب</span>
                <Select
                  name="account_type_id"
                  showSearch
                  className={`full-width height-50 border-radius`}
                  defaultValue={
                    formData.account_type_id && formData.account_type_id
                  }
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        account_type_id: e,
                      };
                    });
                  }}
                  optionFilterProp="label"
                  placeholder={isloading ? "جارٍ التحميل" : "أصل الحساب"}
                  options={accounttypes.map((item) => ({
                    value: item.id,
                    label: `${item.description} - ${item.type_name}`,
                  }))}
                />
              </div>
              <div className="full-width">
                <span>رقم الحساب</span>
                <Input
                  type="text"
                  name="account_id"
                  className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                  placeholder="رقم الحساب"
                  defaultValue={formData.account_id && formData.account_id}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        account_id: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div className="full-width">
                <span>اسم الحساب</span>
                <Input
                  type="text"
                  name="account_name"
                  className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                  placeholder="اسم الحساب"
                  defaultValue={formData.account_name && formData.account_name}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        account_name: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div className="full-width">
                <span>طبيعة الحساب</span>
                <Select
                  name="balance_type"
                  showSearch
                  className={`full-width height-50 border-radius`}
                  defaultValue={formData.balance_type && formData.balance_type}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        balance_type: e,
                      };
                    });
                  }}
                  optionFilterProp="label"
                  placeholder={isloading ? "جارٍ التحميل" : "طبيعة الحساب"}
                  options={[
                    { value: "C", label: "دائن" },
                    { value: "D", label: "مدين" },
                  ]}
                />
              </div>
              <div className="full-width">
                <Checkbox
                  name="is_parent"
                  defaultValue={formData.is_parent && formData.is_parent}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        is_parent: 1 ? e.target.checked : 0,
                      };
                    });
                  }}
                  style={{ width: "100%" }}
                >
                  حساب رئيسي
                </Checkbox>
              </div>
              <div className="full-width">
                <span>الحساب الرئيسي</span>
                <Select
                  name="parent_id"
                  showSearch
                  className={`full-width height-50 border-radius`}
                  defaultValue={formData.parent_id && formData.parent_id}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        parent_id: e,
                      };
                    });
                  }}
                  optionFilterProp="label"
                  placeholder={isloading ? "جارٍ التحميل" : "الحساب الرئيسي"}
                  options={accountsList.map((item) => ({
                    disabled:
                      item.is_parent === "0" ||
                      item.is_parent === 0 ||
                      item.is_parent === null
                        ? true
                        : false,
                    value: item.id,
                    label: `${item.account_id} - ${item.account_name}`,
                  }))}
                />
              </div>
            </form>
          </Modal>
          <Modal
            key={2}
            title="تعديل حساب"
            open={openEditModal}
            onOk={editValidationFun}
            confirmLoading={confirmEditLoading}
            onCancel={handleEditCancel}
            destroyOnClose={true}
          >
            <form
              className="flex flex-column justify-center align-center gap-20 media-flex-column full-width"
              style={{ padding: "20px" }}
            >
              {isAlert && <div className={`${alertName}`}>{alertMsg}</div>}
              <div className="full-width">
                <span>أصل الحساب</span>
                <Select
                  name="account_type_id"
                  showSearch
                  className={`full-width height-50 border-radius`}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        account_type_id: e,
                      };
                    });
                  }}
                  optionFilterProp="label"
                  defaultValue={editFormData && editFormData?.account_type_id}
                  placeholder={isloading ? "جارٍ التحميل" : "أصل الحساب"}
                  options={accounttypes.map((item) => ({
                    value: item.id,
                    label: `${item.description} - ${item.type_name}`,
                  }))}
                />
              </div>
              <div className="full-width">
                <span>رقم الحساب</span>
                <Input
                  type="text"
                  name="account_id"
                  className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                  placeholder="رقم الحساب"
                  defaultValue={editFormData && editFormData?.account_id}
                  onChange={(e) => {
                    setEditFormData((prev) => {
                      return {
                        ...prev,
                        account_id: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div className="full-width">
                <span>اسم الحساب</span>
                <Input
                  type="text"
                  name="account_name"
                  className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                  placeholder="اسم الحساب"
                  defaultValue={editFormData?.account_name}
                  onChange={(e) => {
                    setEditFormData((prev) => {
                      return {
                        ...prev,
                        account_name: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div className="full-width">
                <span>طبيعة الحساب</span>
                <Select
                  name="balance_type"
                  showSearch
                  className={`full-width height-50 border-radius`}
                  defaultValue={editFormData?.balance_type}
                  onChange={(e) => {
                    setEditFormData((prev) => {
                      return {
                        ...prev,
                        balance_type: e,
                      };
                    });
                  }}
                  optionFilterProp="label"
                  placeholder={isloading ? "جارٍ التحميل" : "طبيعة الحساب"}
                  options={[
                    { value: "C", label: "دائن" },
                    { value: "D", label: "مدين" },
                  ]}
                />
              </div>
              <div className="full-width">
                <Checkbox
                  name="is_parent"
                  defaultChecked={editFormData?.is_parent}
                  onChange={(e) => {
                    setEditFormData((prev) => {
                      return {
                        ...prev,
                        is_parent: 1 ? e.target.checked : 0,
                      };
                    });
                  }}
                  style={{ width: "100%" }}
                >
                  حساب رئيسي
                </Checkbox>
              </div>
              <div className="full-width">
                <span>الحساب الرئيسي</span>
                <Select
                  name="parent_id"
                  showSearch
                  className={`full-width height-50 border-radius`}
                  defaultValue={editFormData?.parent_id}
                  onChange={(e) => {
                    setEditFormData((prev) => {
                      return {
                        ...prev,
                        parent_id: e,
                      };
                    });
                  }}
                  optionFilterProp="label"
                  placeholder={isloading ? "جارٍ التحميل" : "الحساب الرئيسي"}
                  options={accountsList.map((item) => ({
                    disabled:
                      item.is_parent === "0" ||
                      item.is_parent === 0 ||
                      item.is_parent === null
                        ? true
                        : false,
                    value: item.id,
                    label: `${item.account_id} - ${item.account_name}`,
                  }))}
                />
              </div>
            </form>
          </Modal>
        </div>
        <div
          className={`over-flow-scroll over-flow-scroll-${theme}`}
          style={{ overflow: "scroll", width: "100%", minHeight: "500px" }}
        >
          <h1>شجرة الحسابات</h1>
          <Tree
            showLine
            titleRender={(data) => (
              <div className="flex gap-10">
                <span>{data.account_id + " - " + data.account_name}</span>
                <span>
                  {" "}
                  <EditOutlined
                    className={`color-primary-${theme}`}
                    onClick={() => handleEdit(data, true)}
                  />
                </span>
                <span>
                  <DeleteOutlined
                    onClick={() => deleteData(data.id, true)}
                    style={{ color: "red" }}
                  />
                </span>
              </div>
            )}
            treeData={tree}
            onSelect={(selectedKeys, info) => {
              if (info.node.is_parent === 1 || info.node.is_parent === "1") {
                console.log("selected", info.node.id);
                setAddAccountDisable(false);
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    parent_id: info.node.id,
                    account_type_id: info.node.account_type_id,
                    balance_type: info.node.balance_type,
                  };
                });
              } else {
                setAddAccountDisable(true);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TreeOfAccountsComp;
