import React, { useContext, useEffect, useState } from "react";
import { Button, DatePicker, Input, Select, Space } from "antd";
import AlertComp from "../subcomponent/AlertComp";
import { AuthCountext } from "../AuthCountext";

function StepTow({
  currentStep,
  stepLenght,
  setFormData,
  formData,
  setDisable,
}) {
  const { SERVER_URL, theme, userToken } = useContext(AuthCountext);
  const [addButtonLoading, setaddButtonLoading] = useState(false);
  const [saveButtonDisable, setSaveButtonDisable] = useState(false);
  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [conteries, setConteries] = useState([]);
  const [ispending, setIspending] = useState(false)

  const getCountries = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/nationality/countries/`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      setConteries(json);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCountries();
    // eslint-addButtonLoading-next-line react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationFun = () => {
    if (
      formData.employeeId !== null &&
      formData.idNumber !== null &&
      formData.countrycode !== null &&
      formData.idExpireDate !== null &&
      formData.passportNumber !== null &&
      formData.passExpireDate !== null
    ) {
      // fetchData();
      setIspending(true)
      setSaveButtonDisable(true);
      setaddButtonLoading(true);
      setIsAlert(true);
      addButtonLoadingTime();
      setAlertMsg(`تم تجاوز الخطوة رقم ${currentStep + 1} بنجاح`);
      setAlertName("alert-success");
      alertShow();
    } else {
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
    }
  };

  const addButtonLoadingTime = () => {
    const timer = setTimeout(
      () => [setaddButtonLoading(false), setIspending(false), setDisable(false)],
      3000
    );

    return () => {
      clearTimeout(timer);
    };
  };

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  return (
    <>
      {isAlert && <AlertComp msg={alertMsg} alert={alertName} />}
      <div className="flex flex-row justify-space-between gap-20 full-width media-flex-column" style={{marginBottom: '20px'}}>
        <div className="full-width flex flex-column align-center gap-20">
          <span
            className="font-large font-bold"
            style={{ alignSelf: "center" }}
          >
            المستندات الثبوتية
          </span>
          <Space direction="vertical" style={{width: '70%'}} className="media-full-width">
            <Select
              name="countrycode"
              showSearch
              className={`full-width height-50 border-radius`}
              onChange={(e) =>
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    countrycode: e,
                  };
                })
              }
              optionFilterProp="label"
              placeholder="الجنسية"
              options={conteries.map((item) => ({
                value: item.countrycode,
                label: item.countryarnationality,
              }))}
            />
            <Input
              type="text"
              name="idNumber"
              className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
              placeholder="رقم الهوية"
              onChange={(e) =>
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    idNumber: e.target.value,
                  };
                })
              }
            />

            <DatePicker
              style={{ width: "100%", height: "50px", borderRadius: "10px" }}
              size={"large"}
              placeholder="تاريخ انتهاء الهوية"
              onChange={(date, dateString) =>
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    idExpireDate: dateString,
                  };
                })
              }
            />
            <Input
              type="text"
              name="passportNumber"
              className={`full-width height-50 border-radius place-holder p-r-10 background-div-${theme} color-${theme}`}
              placeholder="رقم جواز السفر"
              onChange={(e) =>
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    passportNumber: e.target.value,
                  };
                })
              }
            />

            <DatePicker
              style={{ width: "100%", height: "50px", borderRadius: "10px" }}
              size={"large"}
              placeholder="تاريخ انتهاء الجواز"
              onChange={(date, dateString) =>
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    passExpireDate: dateString,
                  };
                })
              }
            />

<Button
              style={{ marginBlock: "10px", width: "100px" }}
              type="primary"
              disabled={saveButtonDisable}
              onClick={() => validationFun()}
              className={`flex flex-row ${
                addButtonLoading ? `justify-space-between` : `justify-center`
              } align-center`}
              loading={addButtonLoading}
            >
              { ispending ? "جارٍ الحفظ" : saveButtonDisable ? "تم الحفظ" :  "اضافة"}
            </Button>
          </Space>
        </div>
      </div>
    </>
  );
}

export default StepTow;
