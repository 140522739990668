import React, { useState, useEffect, useContext } from "react";

import DashboardDiv from "../subcomponent/DashboardDiv";

import { branch, creditCard } from "../../assets/icons";
import { AuthCountext } from "../AuthCountext";

const AccountingSystemComp = () => {
  const { theme } = useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);

  const list = [
    {
      name: "ledgerEntryeis",
      title: "القيود اليومية",
      //   result: totals.paymethod,
      img: creditCard,
      link: "/accounting/ledgerEntryeis",
    },
    {
      name: "account",
      title: "إعداد الحسابات",
      //   result: totals.totalBranches,
      img: branch,
      link: "/accounting/account",
    },
    {
      name: "account",
      title: "الشجرة المحاسبية",
      //   result: totals.totalBranches,
      img: branch,
      link: "/accounting/tree",
    },
    {
      name: "genral-ledger",
      title: "الاستاذ المساعد",
      //   result: totals.paymethod,
      img: creditCard,
      link: "/accounting/report",
    },
    {
      name: "paymethod",
      title: "التقارير المالية",
      //   result: totals.paymethod,
      img: creditCard,
      link: "/accounting/balance-sheet",
    },
  ];

  

  useEffect(() => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className={`flex flex-column color-${theme} gap-10 full-width`}>
      <DashboardDiv list={list} isloading={isloading} />
    </div>
  );
};

export default AccountingSystemComp;
