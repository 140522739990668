import { Button } from "antd";
import ParentComp from "../ParentComp";
import BuyersComp from "./BuyersComp";
import { useState } from "react";

function Buyers() {
  const [openModal, setOpenModal] = useState(false);
  const ParentButton = () => {
    return (
      <Button type="primary" onClick={() => setOpenModal(true)}>
        إضافة عميل 
      </Button>
    );
  };

  
  return (
    <ParentComp
      title={"اعدادات العملاء"}
      prevPage={"نظام المخازن"}
      linkto={"/inventory"}
      button={<ParentButton />}
    >
      <BuyersComp openModal={openModal} setOpenModal={setOpenModal} />
    </ParentComp>
  );
}

export default Buyers;
