import { Button } from "antd";
import ParentComp from "../ParentComp";
import ItemsComp from "./ItemsComp";
import { useState } from "react";

function Items() {
  const [openModal, setOpenModal] = useState(false);
  const ParentButton = () => {
    return (
      <Button type="primary" onClick={() => setOpenModal(true)}>
        إضافة صنف
      </Button>
    );
  };

  
  return (
    <ParentComp
      title={"اعداد الاصناف"}
      prevPage={"نظام المخازن"}
      linkto={"/inventory"}
      button={<ParentButton />}
    >
      <ItemsComp openModal={openModal} setOpenModal={setOpenModal} />
    </ParentComp>
  );
}

export default Items;
