import { Button } from "antd";
import ParentComp from "../ParentComp";
import AccountComp from "./AccountComp";
import { useState } from "react";

function Account() {
  const [openModal, setOpenModal] = useState(false);
  const ParentButton = () => {
    return (
      <Button type="primary" onClick={() => setOpenModal(true)}>
        إضافة حساب
      </Button>
    );
  };

  
  return (
    <ParentComp
      title={"اعداد الحسابات"}
      prevPage={"النظام المحاسبي"}
      linkto={"/accounting"}
      button={<ParentButton />}
    >
      <AccountComp openModal={openModal} setOpenModal={setOpenModal} />
    </ParentComp>
  );
}

export default Account;
