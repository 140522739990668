import { Button } from "antd";
import ParentComp from "../ParentComp";
import UnitsComp from "./UnitsComp";
import { useState } from "react";

function Units() {
  const [openModal, setOpenModal] = useState(false);
  const ParentButton = () => {
    return (
      <Button type="primary" onClick={() => setOpenModal(true)}>
        إضافة وحدة
      </Button>
    );
  };

  
  return (
    <ParentComp
      title={"اعداد الوحدات"}
      prevPage={"نظام المخازن"}
      linkto={"/inventory"}
      button={<ParentButton />}
    >
      <UnitsComp openModal={openModal} setOpenModal={setOpenModal} />
    </ParentComp>
  );
}

export default Units;
