import ParentComp from "../ParentComp";
import InvoicesComp from "./InvoicesComp";

function Invoices() {
  return (
    <ParentComp
      title={"أمر بيع - صرف"}
      prevPage={"نظام المخازن"}
      linkto={"/inventory"}
    >
      <InvoicesComp />
    </ParentComp>
  );
}

export default Invoices;
