import { Button } from "antd";
import ParentComp from "../ParentComp";
import TreeOfAccountsComp from "./TreeOfAccountsComp";
import { useState } from "react";

function TreeOfAccounts() {
  const [openModal, setOpenModal] = useState(false);
  const [disable, setDisable] = useState(true)
  const ParentButton = () => {
    return (
      <Button type="primary" onClick={() => setOpenModal(true)} disabled={disable}>
        إضافة حساب
      </Button>
    );
  };

  
  return (
    <ParentComp
      title={"الشجرة المحاسبية"}
      prevPage={"النظام المحاسبي"}
      linkto={"/accounting"}
      button={<ParentButton />}
    >
      <TreeOfAccountsComp openModal={openModal} setOpenModal={setOpenModal} setAddAccountDisable={setDisable} />
    </ParentComp>
  );
}

export default TreeOfAccounts;
