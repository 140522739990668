import ParentComp from "../ParentComp";
import InventoryReportsComp from "./InventoryReportsComp";

function InventoryReports() {
  return (
    <ParentComp
      title={"التقارير"}
      prevPage={"نظام المخازن"}
      linkto={"/inventory"}
    >
      <InventoryReportsComp />
    </ParentComp>
  );
}

export default InventoryReports;
