import React, { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { AuthCountext } from "./AuthCountext";
import RightBar from "./RightBar";
import Nav from "./Nav";
import Dashboard from "./Dashboard";
import AddEmployee from "./AddEmployee";
import Salary from "./Salary";
import Cut from "./subcomponent/Cut";
import Vacations from "./Vacations";
import FecthEmployee from "./FecthEmployee";
import Settings from "./Settings";
import Branch from "./subcomponent/Branch";
import PositionTitle from "./subcomponent/PositionTitle";
import PyaMethod from "./subcomponent/PayMethod";
import SalaryCut from "./SalaryCut";
import Absence from "./subcomponent/Absence";
import AdvancePayment from "./AdvancePayment";
import Advance from "./subcomponent/Advance";
import Installments from "./subcomponent/Installments";
import SignInScreen from "../auth/SingInScreen";
import Branches from "./Branches";
import Addition from "./Addition";
import Increase from "./subcomponent/Increase";
import Rewards from "./subcomponent/Rewards";
import Overtime from "./subcomponent/Overtime";
import Incentive from "./subcomponent/Incentives";
import CreatUser from "../auth/CreatUser";
import Loader from "./Loader";
import WindowSize from "./subcomponent/WindowSize";
import UpdateEmployee from "./UpdateEmployee";
import Employees from "./Employees";
import Icon from "./Icon";
import {
  closeMenuDark,
  closeMenuLight,
  menuDark,
  menuLight,
} from "../assets/icons";
import MainDocs from "./subcomponent/MainDocs";
import MainDocsRecords from "./subcomponent/MainDocsRecords";
import SearchPage from "./SearchPage";
import UsersAuthBranch from "../auth/UsersAuthBranch";
import PdfBrowserRender from "./subcomponent/PdfBrowserRender";
import Footer from "./Footer";
import Account from "./accounting/Account";
import Transactions from "./accounting/Transactions";
import Report from "./accounting/Report";
import AccountingSystem from "./accounting/AccountingSystem";
import LedgerEntryeis from "./accounting/LedgerEntryeis";
import EditTransactions from "./accounting/EditTransactions";
import ShowTransactions from "./accounting/ShowTransactions";
import BalanceSheet from "./accounting/BalanceSheet";
import TreeOfAccounts from "./accounting/TreeOfAccounts";
import InventorySystem from "./inventory/InventorySystem";
import Warehouses from "./inventory/Warehouses";
import Categories from "./inventory/Categories";
import Units from "./inventory/Units";
import Items from "./inventory/Items";
import ItemUnits from "./inventory/ItemUnits";
import Suppliers from "./inventory/Suppliers";
import PurchaseOrders from "./inventory/PurchaseOrders";
import Invoices from "./inventory/Invoices";
import Buyers from "./inventory/Buyers";
import InventoryReports from "./inventory/InventoryReports";
import StockTaking from "./inventory/StockTaking";

function Land() {
  const {
    userAdmin,
    userName,
    branchId,
    theme,
    setTitle,
    usersData,
    openRightBar,
    setOpenRightBar,
  } = useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);

  const windowSize = WindowSize();

  useEffect(() => {
    const loader = () => {
      const timer = setTimeout(() => setIsloading(false), 1000);
      return () => {
        clearTimeout(timer);
      };
    };
    loader();
  }, []);

  return isloading ? (
    <div
      className={`flex align-center justify-center full-height full-width background-${theme}`}
    >
      <Loader />
    </div>
  ) : (
    <div className="flex position-relative full-height full-width">
      {setTitle("نظام الرواتب- الصفحة الرئيسية")}
      {userName && usersData ? (
        branchId ? (
          <>
            <RightBar />

            {windowSize[0] < 900 && (
              <div
                className="flex flex-row justify-center align-center"
                style={{
                  position: "absolute",
                  top: "25px",
                  right: "10px",
                  zIndex: "105",
                }}
              >
                <Icon
                  icon={
                    theme === "dark"
                      ? openRightBar
                        ? closeMenuDark
                        : menuDark
                      : openRightBar
                      ? closeMenuLight
                      : menuLight
                  }
                  w={30}
                  h={30}
                  onClick={() => {
                    setOpenRightBar(!openRightBar);
                  }}
                />
              </div>
            )}
            {/* <SearchBar /> */}
            <div
              className={`land position-relative flex flex-column justify-start align-start full-width p-50 over-flow-scroll over-flow-scroll-${theme} background-${theme} color-${theme}`}
              style={{ height: "100%" }}
            >
              <Nav />
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/employee/:id" element={<FecthEmployee />} />
                <Route
                  path="/employee/updateemployee/:id"
                  element={<UpdateEmployee />}
                />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/employee/" element={<Employees />} />
                <Route path="/employee/addEmployee" element={<AddEmployee />} />
                <Route path="/cut" element={<Cut />} />
                <Route path="/cut/:id" element={<Cut />} />
                <Route path="/salary" element={<Salary />} />
                <Route path="/salarycut" element={<SalaryCut />} />
                <Route path="/vacation" element={<Vacations />} />
                <Route path="/vacation/:id" element={<Vacations />} />
                <Route path="/branches" element={<Branches />} />
                <Route path="/absence" element={<Absence />} />
                <Route path="/absence/:id" element={<Absence />} />
                <Route path="/advancepayment" element={<AdvancePayment />} />
                <Route path="/advance" element={<Advance />} />
                <Route path="/advance/:id" element={<Advance />} />
                <Route path="/installments" element={<Installments />} />
                <Route path="/installments/:id" element={<Installments />} />
                <Route path="/addition" element={<Addition />} />
                <Route path="/addition/:id" element={<Addition />} />
                <Route path="/increase" element={<Increase />} />
                <Route path="/increase/:id" element={<Increase />} />
                <Route path="/overtime" element={<Overtime />} />
                <Route path="/overtime/:id" element={<Overtime />} />
                <Route path="/incentives" element={<Incentive />} />
                <Route path="/incentives/:id" element={<Incentive />} />
                <Route path="/rewards" element={<Rewards />} />
                <Route path="/search" element={<SearchPage />} />
                <Route path="/rewards/:id" element={<Rewards />} />
                <Route path="/pdf" element={<PdfBrowserRender />} />
                <Route path="/pdf/:page/:id" element={<PdfBrowserRender />} />
                <Route path="accounting" element={<AccountingSystem />} />
                <Route path="accounting/account" element={<Account />} />
                <Route
                  path="accounting/transactions"
                  element={<Transactions />}
                />
                <Route
                  path="accounting/edit-transactions/:id"
                  element={<EditTransactions />}
                />
                <Route
                  path="accounting/show-transactions/:id"
                  element={<ShowTransactions />}
                />
                <Route path="accounting/report" element={<Report />} />
                <Route path="accounting/balance-sheet" element={<BalanceSheet />} />
                <Route path="accounting/tree" element={<TreeOfAccounts />} />
                <Route
                  path="accounting/ledgerEntryeis"
                  element={<LedgerEntryeis />}
                />
                <Route path="inventory" element={<InventorySystem />} />
                <Route path="inventory/warehouses" element={<Warehouses />} />
                <Route path="inventory/categories" element={<Categories />} />
                <Route path="inventory/units" element={<Units />} />
                <Route path="inventory/items" element={<Items />} />
                <Route path="inventory/itemsunits" element={<ItemUnits />} />
                <Route path="inventory/suppliers" element={<Suppliers />} />
                <Route path="inventory/PurchaseOrders" element={<PurchaseOrders />} />
                <Route path="inventory/buyers" element={<Buyers />} />
                <Route path="inventory/invoices" element={<Invoices />} />
                <Route path="inventory/inventoryReports" element={<InventoryReports />} />
                <Route path="inventory/StockTaking" element={<StockTaking />} />
                {(userAdmin === "1" || userAdmin === 1) && (
                  <>
                    <Route path="settings/users" element={<CreatUser />} />

                    <Route
                      path="settings/users/usersauthbranch"
                      element={<UsersAuthBranch />}
                    />
                    <Route
                      path="settings/users/usersauthbranch/:id"
                      element={<UsersAuthBranch />}
                    />
                    <Route path="settings/maindocs" element={<MainDocs />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="settings/branch" element={<Branch />} />
                    <Route path="settings/jobs" element={<PositionTitle />} />
                    <Route path="settings/paymethod" element={<PyaMethod />} />
                    <Route
                      path="settings/maindocs/maindocsrecords"
                      element={<MainDocsRecords />}
                    />
                  </>
                )}
              </Routes>
              <Footer />
            </div>
          </>
        ) : (
          <Branches />
        )
      ) : (
        <SignInScreen />
      )}
    </div>
  );
}

export default Land;
