import React, { useContext, useState } from "react";
import { Button, Checkbox, DatePicker, Input, Select, Space } from "antd";
import AlertComp from "../subcomponent/AlertComp";
import { AuthCountext } from "../AuthCountext";

function StepOne({
  currentStep,
  stepLenght,
  setFormData,
  formData,
  setDisable,
}) {
  const { theme } = useContext(AuthCountext);
  const [addButtonLoading, setAddButtonLoading] = useState(false);
  const [saveButtonDisable, setSaveButtonDisable] = useState(false);
  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [ispending, setIspending] = useState(false);

  const validationFun = () => {
    if (
      formData.fulltime !== null &&
      formData.birthdate !== null &&
      formData.email !== null &&
      formData.name !== null &&
      formData.phone !== null &&
      formData.sex !== null
    ) {
      // fetchData();
      setIspending(true);
      setSaveButtonDisable(true);
      setAddButtonLoading(true);
      addButtonLoadingTime();
      setIsAlert(true);
      setAlertMsg(`تم تجاوز الخطوة رقم ${currentStep + 1} بنجاح`);
      setAlertName("alert-success");
      alertShow();
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
    }
  };

  const addButtonLoadingTime = () => {
    const timer = setTimeout(
      () => [
        setAddButtonLoading(false),
        setIspending(false),
        setDisable(false),
      ],
      3000
    );

    return () => {
      clearTimeout(timer);
    };
  };

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  return (
    <>
      {isAlert && <AlertComp msg={alertMsg} alert={alertName} />}
      <div
        className="flex flex-row justify-space-between gap-20 full-width media-flex-column"
        style={{ marginBottom: "20px" }}
      >
        <div className="full-width flex flex-column align-center gap-20">
          <span
            className="font-large font-bold"
            style={{ alignSelf: "center" }}
          >
            بيانات الموظف
          </span>
          <Space
            direction="vertical"
            style={{ width: "70%" }}
            className="media-full-width"
          >
            <Input
              type="text"
              name="name"
              className={`full-width height-50 border-radius place-holder p-r-10 background-div-${theme} color-${theme}`}
              placeholder="الإسم"
              onChange={(e) =>
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    name: e.target.value,
                  };
                })
              }
            />
            <Input
              type="text"
              name="phone"
              className={`full-width height-50 border-radius place-holder p-r-10 background-div-${theme} color-${theme}`}
              placeholder="الجوال"
              onChange={(e) =>
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    phone: e.target.value,
                  };
                })
              }
            />
            <Input
              type="email"
              name="email"
              className={`full-width height-50 border-radius place-holder p-r-10 background-div-${theme} color-${theme}`}
              placeholder="الايميل"
              onChange={(e) =>
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    email: e.target.value,
                  };
                })
              }
            />
            <DatePicker
              style={{ width: "100%", height: "50px", borderRadius: "10px" }}
              size={"large"}
              placeholder="تاريخ الميلاد"
              onChange={(date, dateString) =>
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    birthdate: dateString,
                  };
                })
              }
            />
            <Select
              name="sex"
              className={`full-width height-50 border-radius place-holder background-div-${theme} color-${theme}`}
              onChange={(e) =>
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    sex: e,
                  };
                })
              }
              placeholder="الجنس"
              options={[
                {
                  value: "male",
                  label: "ذكر",
                },
                {
                  value: "female",
                  label: "أنثى",
                },
              ]}
            />

            <div className="flex flex-row align-center justify-space-between gap-10 full-width">
              <div className="flex flex-row align-center justify-space-between">
                <Checkbox
                  id="fulltime"
                  type="checkbox"
                  name="fulltime"
                  onChange={(e) =>
                    setFormData((prevFormData) => {
                      return {
                        ...prevFormData,
                        fulltime: e.target.checked ? true : false,
                      };
                    })
                  }
                >
                  دوام كامل
                </Checkbox>
              </div>
              <div className="flex flex-row align-center justify-space-between">
                <Checkbox
                  id="employee"
                  type="checkbox"
                  name="employee"
                  onChange={(e) =>
                    setFormData((prevFormData) => {
                      return {
                        ...prevFormData,
                        employee: e.target.checked ? true : false,
                      };
                    })
                  }
                >
                  موظف رسمي
                </Checkbox>
              </div>
            </div>
            <Button
              style={{ marginBlock: "10px", width: "100px" }}
              type="primary"
              disabled={saveButtonDisable}
              onClick={() => validationFun()}
              className={`flex flex-row ${
                addButtonLoading ? `justify-space-between` : `justify-center`
              } align-center`}
              loading={addButtonLoading}
            >
              {ispending
                ? "جارٍ الحفظ"
                : saveButtonDisable
                ? "تم الحفظ"
                : "اضافة"}
            </Button>
          </Space>
        </div>
      </div>
    </>
  );
}

export default StepOne;
